import styled from "@emotion/styled";
import { colors } from "../../utils";

export const Container = styled.div`
  align-items: center;
  background: #ffffff;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  padding: 5px;
  position: absolute;
  width: 90%;
  z-index: 1060;

  ${(props) => props.isOpen && `background: ${colors.brightBlue};`}
`;
