import PropTypes from "prop-types";
import { stringify } from "query-string";
import all from "ramda/src/all";
import compose from "ramda/src/compose";
import contains from "ramda/src/contains";
import flatten from "ramda/src/flatten";
import intersection from "ramda/src/intersection";
import isEmpty from "ramda/src/isEmpty";
import lensPath from "ramda/src/lensPath";
import map from "ramda/src/map";
import over from "ramda/src/over";
import pipe from "ramda/src/pipe";
import prop from "ramda/src/prop";
import set from "ramda/src/set";
import toPairs from "ramda/src/toPairs";
import view from "ramda/src/view";
import { PureComponent } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { A11yAnnouncement, ErrorBar } from "../../components";
import { runValidationSet } from "../../components/Form/form";
import validationMethods from "../../components/Form/validations";
import withWorkspaceId from "../../hoc/withWorkspaceId";
import { actions, selectors } from "../../store";
import { isAbsent, isPresent } from "../../utils";
import { types as dateRangeTypes } from "../search.basic/basic-search.utils";
import { ClearButton } from "./_button.clear";
import { SubmitButton } from "./_button.submit";
import { SubmitWrapper } from "./_buttons.submit.wrapper";
import { Form } from "./_form";
import { Wrapper } from "./_wrapper";
import { Fields } from "./fields";
import { makeQueryMapper } from "./map-to-query";

export class AdvancedSearch extends PureComponent {
  constructor(props) {
    super(props);

    this.clearAllErrors = clearAllErrors.bind(this);
    this.clearFieldError = clearFieldError.bind(this);
    this.clearFormState = clearFormState.bind(this);
    this.handleSubmit = handleSubmit.bind(this);
    this.resetState = resetState.bind(this);
    this.toggleGroupExpanded = toggleGroupExpanded.bind(this);
    this.touchField = touchField.bind(this);
    this.updateFieldError = updateFieldError.bind(this);
    this.updateFieldValue = updateFieldValue.bind(this);
    this.validate = validate.bind(this);
    this.validateField = validateField.bind(this);

    this.setFormRef = (el) => (this.form = el);

    const { savedFormState = {} } = props.advancedSearchData.selectedDepartment;

    this.state = !isEmpty(savedFormState)
      ? savedFormState
      : getDefaultStateFromProps(this.props);
  }

  componentDidMount() {
    const { advancedSearchData } = this.props;
    const { fields } = advancedSearchData;
    const config = fields.landCorner;

    if (isPresent(config)) {
      // This integration point gets data out of the web component and puts it
      // back into the Form state management (managed by React).
      this.form.addEventListener("select-land-corners:change", (e) => {
        this.updateFieldValue({ key: "landCorner", config })(e.detail);
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedDepartment } = this.props.advancedSearchData;
    const shouldUpdate = determineShouldUpdate(
      selectedDepartment,
      prevProps.advancedSearchData.selectedDepartment
    );

    const dateRange = determineDateRangeProp(selectedDepartment);
    const updatedValue = determineDateRangeValue(selectedDepartment);

    if (shouldUpdate) {
      this.clearFormState();
      this.setState(set(lensPath(["form", "values", updatedValue]), dateRange));
    }
  }

  componentWillUnmount() {
    this.props.updateAdvancedSearchState({
      state: this.state,
      workspaceID: this.props.workspaceID,
    });
  }

  render() {
    const { advancedSearchData } = this.props;
    const { selectedDepartment, departments } = advancedSearchData;
    const { hasEmptyDates, datesLoading } = selectedDepartment;

    const errorMessage = hasEmptyDates
      ? "No documents to search in department."
      : "";

    const preloadLibs = (targetDeptCode) =>
      (departments || []).find(({ value }) => value === targetDeptCode);

    const requiredFieldsValid = () => {
      const { required } = selectedDepartment;
      const { touched, errors } = this.state.form;

      if (typeof required === "undefined") return true;

      const delta = intersection(required, Array.from(touched));

      if (delta.length !== required.length) return false;

      return required.every((field) => isAbsent(errors[field]));
    };

    const submittable = requiredFieldsValid();

    const submitForm = (e) => {
      if (!submittable) {
        e.preventDefault();
      }
    };

    const disableSearch =
      datesLoading || hasEmptyDates || submittable === false;

    return (
      <Wrapper>
        <Helmet>
          <title>Advanced Search</title>
        </Helmet>
        <A11yAnnouncement>Navigated to advanced search page</A11yAnnouncement>
        <ErrorBar error={errorMessage} />
        <Form
          onSubmit={this.handleSubmit}
          disabled={hasEmptyDates}
          ref={this.setFormRef}
        >
          {preloadLibs("LC") && (
            <Helmet>
              <script src="/components/select-land-corners.mjs" type="module" />
            </Helmet>
          )}
          <Fields
            advancedSearchData={advancedSearchData}
            fetchDepartmentDateRanges={this.props.fetchDepartmentDateRanges}
            state={this.state}
            updateFieldValue={this.updateFieldValue}
            updateFieldError={this.updateFieldError}
            toggleGroupExpanded={this.toggleGroupExpanded}
            touchField={this.touchField}
          />
          <SubmitWrapper>
            <SubmitButton
              type="submit"
              aria-disabled={disableSearch}
              disabled={disableSearch}
              onClick={submitForm}
            >
              Search
            </SubmitButton>

            <ClearButton
              data-testid="advanced-search__clear"
              type="button"
              onClick={this.resetState}
            >
              Clear
            </ClearButton>
          </SubmitWrapper>
        </Form>
      </Wrapper>
    );
  }
}

AdvancedSearch.displayName = "AdvancedSearch";

AdvancedSearch.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  advancedSearchData: PropTypes.shape({
    fields: PropTypes.object,
    selectedDepartment: PropTypes.shape({
      layout: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
      partyFields: PropTypes.arrayOf(PropTypes.string).isRequired,
      hasEmptyDates: PropTypes.bool.isRequired,
      code: PropTypes.string.isRequired,
      legalFields: PropTypes.object,
    }).isRequired,
  }),
  workspaceID: PropTypes.string.isRequired,
  fetchDepartmentDateRanges: PropTypes.func,
  updateAdvancedSearchState: PropTypes.func,
};

AdvancedSearch.defaultProps = {
  advancedSearchData: {
    fields: {},
    selectedDepartment: {
      layout: [],
      savedFormState: {},
    },
    legalFields: {},
  },
  fetchDepartmentDateRanges: () => {},
};

const mapDispatchToProps = (dispatch) => ({
  originalDispatch: dispatch,
  updateAdvancedSearchState: ({ state, workspaceID }) =>
    dispatch(
      actions.workspaces.updateAdvancedSearchState({ state, workspaceID })
    ),
});

export const mapStateToProps = (state) => ({
  advancedSearchData: selectors.workspaces.getAdvancedSearchData(state),
});

export default compose(
  withRouter,
  withWorkspaceId,
  connect(mapStateToProps, mapDispatchToProps)
)(AdvancedSearch);

// --------------------------------------------------------------------- private
const hasChanged = (oldVal, newVal) => newVal !== oldVal && newVal.length > 1;

const determineShouldUpdate = (selectedDepartment, pastSelectedDepartment) => {
  const { recordedDateRange, instDateRange, applicationDateRange } =
    selectedDepartment;

  const {
    recordedDateRange: pastRecordedDateRange,
    instDateRange: pastInstDateRange,
    applicationDateRange: pastApplicationDateRange,
  } = pastSelectedDepartment;

  return (
    hasChanged(pastRecordedDateRange, recordedDateRange) ||
    hasChanged(pastInstDateRange, instDateRange) ||
    hasChanged(pastApplicationDateRange, applicationDateRange)
  );
};

function resetState() {
  this.setState(getDefaultStateFromProps(this.props, true));

  const lc = this.form.querySelector("select-land-corners");

  if (lc !== null) {
    lc.dispatchEvent(new CustomEvent("select-land-corners:reset"));
  }
}

function updateFieldValue({ key, config }) {
  const { validations = [] } = config;
  const mappedValidations = validations.map((v) => validationMethods[v]);

  return (value) => {
    const error = runValidationSet(mappedValidations, value, config);

    const paths = {
      value: lensPath(["form", "values", key]),
      touched: lensPath(["form", "touched"]),
      error: lensPath(["form", "errors", key]),
    };

    this.setState(
      pipe(
        set(paths.value, value),
        over(paths.touched, (touched) => new Set([...touched, key])),
        set(paths.error, isPresent(error) ? error : "")
      )
    );
  };
}

function touchField(key) {
  const modifier = (touched) => new Set([...touched, key]);

  return this.setState(
    over(lensPath(["form", "touched"]), modifier, this.state)
  );
}

function updateFieldError(key) {
  return (value) =>
    this.setState(set(lensPath(["form", "errors", key]), value));
}

function clearFieldError(key) {
  return () => this.updateFieldError(key)("");
}

function clearAllErrors(c) {
  this.setState(
    over(
      lensPath(["form", "errors"]),
      map(() => "")
    ),
    c
  );
}

function clearFormState() {
  this.setState(
    set(lensPath(["form"]), { values: {}, errors: {}, touched: new Set() })
  );
}

function toggleGroupExpanded(index) {
  const lens = lensPath(["groupExpanded", index]);
  const curState = view(lens, this.state);

  return () => this.setState(set(lens, !curState));
}

function handleSubmit(event) {
  event.preventDefault();

  if (this.validate()) {
    this.clearAllErrors(() => {
      const { selectedDepartment } = this.props.advancedSearchData;
      const { code, legalFields, partyFields } = selectedDepartment;
      const formValues = { department: code, ...this.state.form.values };
      const mapToQuery = makeQueryMapper({ legalFields, partyFields });
      const query = mapToQuery(formValues);

      this.props.history.push({
        pathname: "/results",
        search: stringify(query),
        state: { workspaceID: this.props.workspaceID },
      });
    });
  }
}

function getDefaultStateFromProps(props, cleared = false) {
  const { selectedDepartment } = props.advancedSearchData;
  const { groups = [], hasEmptyDates } = selectedDepartment;

  const dateValue = determineDateRangeProp(selectedDepartment);
  const dateRange = determineDateRangeValue(selectedDepartment);

  return {
    hasEmptyDates,
    groupExpanded: groups.map(prop("isOpen")),
    cleared,
    form: {
      values: { [dateRange]: dateValue },
      errors: {},
      touched: new Set(),
    },
  };
}

function validateField({ name, value, config }) {
  const { validations = [] } = config;
  const mappedValidations = validations.map((v) => validationMethods[v]);
  const error = runValidationSet(mappedValidations, value, config);

  if (isPresent(error)) {
    this.updateFieldError(name)(error);
  } else {
    this.clearFieldError(name)();
  }
}

function validate() {
  const { fields } = this.props.advancedSearchData;
  const { values } = this.state.form;

  const errors = toPairs(values).map(([key, val]) => {
    if (!fields[key]) return "";

    const { validations = [] } = fields[key];
    const mappedValidations = validations.map((v) => validationMethods[v]);
    const error = runValidationSet(mappedValidations, val, fields[key]);

    if (error) this.updateFieldError(key)(error);

    return error;
  });

  if (all(isEmpty)(errors)) return true;

  return false;
}

function layoutContains(key, complexObject = {}) {
  return contains(key, flatten(complexObject));
}

const { INSTRUMENT, RECORDED, APPLICATION } = dateRangeTypes;

function determineDateRangeValue(props) {
  const { fullTextSearchDateRangeProp: defaultDateRange, layout } = props;

  const layoutContainsField = layoutContains(defaultDateRange, layout);
  const layoutHasMeetingDate = layoutContains("meetingDateRange", layout);

  const shouldBeMeetingDate =
    !layoutContainsField &&
    layoutHasMeetingDate &&
    defaultDateRange === INSTRUMENT;

  return shouldBeMeetingDate ? "meetingDateRange" : defaultDateRange;
}

function determineDateRangeProp(props) {
  const {
    fullTextSearchDateRangeProp: searchBy,
    recordedDateRange,
    instDateRange,
    applicationDateRange,
  } = props;

  if (searchBy === RECORDED) return recordedDateRange;
  if (searchBy === APPLICATION) return applicationDateRange;
  if (searchBy === INSTRUMENT || searchBy === "meetingDateRange")
    return instDateRange;
}
