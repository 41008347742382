import { css } from "@emotion/react";

export default {
  header: css`
    align-items: flex-start;
    display: flex;
    height: 60px;
    padding-left: 1rem;
  `,
  wrapper: css`
    background: #ffffff;
    border: 1px solid rgba(4, 29, 68, 0.15);
    border-radius: 3px;
    margin: 16px 0;
    position: relative;
    width: 100%;
    padding-bottom: 10px;
  `,
  tableBody: css`
    display: flex;
    flex-direction: column;
    min-height: 90px;
    padding-left: 10rem;

    & > div {
      color: #1c447c;
      font-size: 13px;
      font-weight: 600;
      line-height: 1.5;
      padding-top: 0.6rem;
    }
  `,
  tableHeader: css`
    background: rgba(4, 29, 68, 0.04);
    border-bottom: 1px solid rgba(4, 29, 68, 0.15);
    border-top: 1px solid rgba(4, 29, 68, 0.15);
    display: flex;
    height: 40px;
    padding-left: 10rem;

    & > div {
      align-items: center;
      color: rgba(4, 29, 68, 0.7);
      display: flex;
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
    }
  `,
  entryOption: css`
    align-items: center;
    display: flex;
    font-size: 13px;
    height: 100%;
    margin: 0 16px;
    white-space: nowrap;
    color: #041d44;
  `,
  entryButton: css`
    color: #006ed5;
    font-size: 13px;
  `,
  table: {
    buttonWrap: css`
      color: #006dd5;
      display: inline-block;
      width: 20px;

      button {
        margin-right: 5px;
      }
    `,
    column: css`
      overflow-x: hidden;
      padding: 0 8px;
      text-overflow: ellipsis;
      white-space: nowrap;
    `,
    small: css`
      width: 20%;
    `,
    medium: css`
      width: 30%;
    `,
    large: css`
      width: 50%;
    `,
  },
};
