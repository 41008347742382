import { css } from "@emotion/react";

const lineStyles = `
    align-items: center;
    box-sizing: border-box;
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    padding: 12px 8px;
    width: 100%;
  `;

export const style = {
  wrap: css`
    margin: 1rem 0 0 1rem;
    background: #fff;
    border: 1px solid #dbdde4;
    border-radius: 4px;
  `,
  innerWrap: css`
    padding: 8px 16px;
  `,
  help: css`
    display: inline-block;
    margin-left: 10px;
    position: relative;
  `,
  alignRight: css`
    text-align: right;
  `,
  list: css`
    align-content: start;
    display: inline-grid;
    font-size: 14px;
    grid-column-gap: 10px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    margin: 0;
    width: 100%;
  `,
  explanation: {
    container: css`
      background: #ffffff;
      border: 1px solid rgba(4, 29, 68, 0.15);
      border-radius: 4px;
      bottom: -23px;
      -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
      padding: 10px;
      position: absolute;
      right: 35px;
      white-space: nowrap;
    `,
    color: css`
      color: rgba(4, 29, 68, 0.6);
    `,
  },
  totalColor: css`
    color: #041d44;
  `,
  total: css`
    align-items: center;
    box-sizing: border-box;
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    padding: 12px 8px;
    width: 100%;
    border-bottom: none;
    font-weight: 700;
  `,
  line: (showLine = true) => css`
    border-bottom: ${showLine ? "1px solid rgba(33, 33, 33, 0.15);" : "0"};
    ${lineStyles}
  `,
  lineGeneral: css`
    ${lineStyles}
  `,
  triangle: css`
    background: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    position: relative;

    &::after {
      border: 8px solid #000000;
      border-color: transparent #ffffff #ffffff transparent;
      bottom: 7px;
      box-shadow: 2px 3px 3px 0 rgba(0, 0, 0, 0.2);
      box-sizing: border-box;
      content: "";
      height: 0;
      position: absolute;
      right: -14px;
      transform: rotate(-45deg);
      transform-origin: 0 0;
      width: 0;
    }
  `,
};
