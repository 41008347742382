import { css } from "@emotion/react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { selectors } from "../../../../store";
import { makeLoginRoute } from "../../../../utils";

const copy = "Place Your Order";

const style = (disabled) => css`
  background: rgba(0, 109, 213, 1);
  border-radius: 3.5px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: block;
  font-size: 13px;
  font-weight: 600;
  margin: 8px 0;
  padding: 10px 8px;
  text-align: center;
  width: 100%;

  ${disabled &&
  `opacity: 0.5;
    pointer-events: none;`}
`;

export const PurchaseButton = ({ onClick, nCourtEnabled, disabled }) => {
  const location = useLocation();
  const loggedIn = useSelector(selectors.user.getLoggedIn);

  if (loggedIn === false) {
    return (
      <Link
        css={style(disabled)}
        to={makeLoginRoute(location)}
        data-testid="orderButton"
      >
        Login / Register to Order
      </Link>
    );
  }

  if (nCourtEnabled === true) {
    return (
      <button css={style(disabled)} data-testid="orderButton" onClick={onClick}>
        {copy}
      </button>
    );
  }

  return (
    <Link
      css={style(disabled)}
      to="/cart/checkout/"
      data-testid="orderButton"
      onClick={onClick}
    >
      {copy}
    </Link>
  );
};
