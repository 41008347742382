import styled from "@emotion/styled";

export const EmailContainer = styled.div`
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 3px;
  display: grid;
  grid-template:
    "icon emailheader" auto
    "icon content" auto
    / 50px auto;
  max-width: 100%;
  padding: 50px 75px;

  & img {
    grid-area: icon;
  }
`;
