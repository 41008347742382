import { css } from "@emotion/react";
import includes from "ramda/src/includes";
import propOr from "ramda/src/propOr";
import { useSelector } from "react-redux";
import { selectors } from "../../../../../../store";
import { isAbsent } from "../../../../../../utils";

const styles = {
  contactWrapper: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;
  `,
  a: css`
    color: #006dd5;
    text-decoration: underline;
  `,
  title: css`
    font-size: 1.2rem;
    font-weight: 500;
    padding: 1.2rem 0;
  `,
  support: css`
    font-size: 1.1rem;
    font-weight: 500;
    padding: 20px 0 5px 0;
  `,
  tos: css`
    padding-top: 30px;
  `,
};

const PropertyAlertContactSettings = ({ form }) => {
  const fraudAlert = useSelector(selectors.configuration.getFraudAlert);
  const alertMethods = propOr([], "alertMethods", fraudAlert);
  const countyUsesPhone =
    includes("sms", alertMethods) || includes("phone", alertMethods);

  if (fraudAlert.enabled !== true || isAbsent(alertMethods)) return null;

  return (
    <>
      <h3 css={styles.title}>Property Alert Notification(s) Preferences</h3>
      <p>
        Choose your preferences for how you’d like to be notified about Property
        Alerts. If you want to disable a Property Alert entirely, please visit
        the{" "}
        <a css={styles.a} href="/account/property-alert">
          Property Alert Dashboard
        </a>
        .
        {countyUsesPhone &&
          ' To stop receiving messages via phone or text, unselect the options below or reply to an SMS notification with "STOP."'}
      </p>
      <div css={styles.contactWrapper}>
        {form.contactByEmail}
        {includes("sms", alertMethods) && form.contactByText}
        {includes("phone", alertMethods) && form.contactByPhone}
      </div>
      <p>
        If there is a match to your Property Alert criteria, you can receive at
        most one message a day.
      </p>
      {countyUsesPhone && <p>Message and data rates may apply.</p>}
      {countyUsesPhone && form.consent}
      <h4 css={styles.support}>For support:</h4>
      <p>
        Email:{" "}
        <a css={styles.a} href="mailto:support@govos.com">
          support@govos.com
        </a>
      </p>
      {countyUsesPhone && <p>For help with Text Messages reply HELP</p>}
      <p>Call: (833) 456-3453</p>
      <p css={styles.tos}>
        <a css={styles.a} href="https://www.govos.com/terms-and-conditions">
          Terms and Conditions
        </a>
        {"  |  "}
        <a css={styles.a} href="https://www.govos.com/privacy-policy">
          Privacy Policy
        </a>
      </p>
    </>
  );
};

export default PropertyAlertContactSettings;
