import styled from "@emotion/styled";

export const ChooseContainer = styled.div`
  border: 1px solid #dadde2;
  border-radius: 4px;
  background: #fff;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px 0;
`;
