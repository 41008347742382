import { css } from "@emotion/react";
import { isAbsent } from "../../utils";

const style = (disabled) => css`
  background-color: #006dd5;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
  padding: 8px 32px 9px;
  padding: 5px 12px;
  font-size: 0.8rem;
  opacity: ${disabled ? 0.5 : 1};
  pointer-events: ${disabled ? "none" : "auto"};
  cursor: ${disabled ? "not-allowed" : "pointer"};
  display: flex;
  align-items: center;
`;

const ConfigurableAction = ({ label, url = "#" }) => {
  if (isAbsent(label)) return null;

  const disabled = url === "#";
  const target = url[0] === "/" ? "_self" : "_blank";

  return (
    <a css={style(disabled)} target={target} rel="noreferrer" href={url}>
      {label}
    </a>
  );
};

export default ConfigurableAction;
