import { css } from "@emotion/react";
import isNil from "ramda/src/isNil";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingSpinner } from "../../../../../../components";
import { useFraudAlertTerms } from "../../../../../../hooks/termsAndConditions";
import { actions, selectors } from "../../../../../../store";
import { SettingsPane } from "../../settings-pane";
import { ActiveAlerts, AlertsDisabled, MatchHistory } from "./components";

const modalStyle = css`
  background-color: #ffffff;
  height: auto;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
`;

const FraudAlert = () => {
  const [showTerms, setShowTerms] = useState(false);
  const { fetchAlerts, fetchMatchRecords } = actions.fraudAlert;
  const {
    getAlertsList,
    getMatchesList,
    isAlertListLoading,
    isMatchesListLoading,
  } = selectors.fraudAlert;
  const dispatch = useDispatch();

  const { display: displayTerms, hasAccepted } = useFraudAlertTerms({
    submit: () => setShowTerms(false),
    cancel: () => setShowTerms(false),
  });

  const openTermsModal = (event) => {
    event.preventDefault();

    const shouldAcceptTerms = !hasAccepted;
    setShowTerms(shouldAcceptTerms);
  };

  useEffect(() => void dispatch(fetchAlerts()), []);
  useEffect(() => void dispatch(fetchMatchRecords()), []);

  const alerts = useSelector(getAlertsList);
  const matches = useSelector(getMatchesList);
  const loadingAlerts = useSelector(isAlertListLoading);
  const loadingMatches = useSelector(isMatchesListLoading);

  const addAlerts = () => {
    if (isNil(hasAccepted) || isNil(alerts)) {
      return <LoadingSpinner />;
    }

    if (hasAccepted) {
      return <ActiveAlerts alerts={alerts} loading={loadingAlerts} />;
    }

    if (showTerms) {
      return <div css={modalStyle}>{displayTerms()}</div>;
    }

    return <AlertsDisabled openTermsModal={openTermsModal} />;
  };

  return (
    <SettingsPane title="Property Alert">
      <MatchHistory matches={matches} loading={loadingMatches} />
      {addAlerts()}
    </SettingsPane>
  );
};

export default FraudAlert;
