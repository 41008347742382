import { css } from "@emotion/react";

export default css`
  background-color: #ffffff;
  border: 1px solid #dadde2;
  border-radius: 4px;
  color: #041d44;
  padding: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 175px 175px 1fr;
  grid-template-rows: min-content min-content min-content auto min-content;
  grid-template-areas:
    "error       error       error         error         error       "
    "title       price       date          date          org-title   "
    "capacity    price       cancel-button change-button org-name    "
    "choose-plan choose-plan choose-plan   choose-plan   choose-plan "
    ".           .           .             trailing      trailing    ";
  gap: 10px;
`;
