import { parse } from "query-string";
import prop from "ramda/src/prop";
import { Link, useLocation } from "react-router-dom";
import { getNewSearchURL } from "../../../utils";
import useDocument from "../../doc-detail.common/useDocument";
import link from "./_summary.link";

const LegalLink = ({ value = "" }) => {
  const docData = useDocument();
  const location = useLocation();
  const query = parse(location.search);

  const searchLink = (department) =>
    getNewSearchURL({
      query: { ...query, department },
      value,
      key: "legalDescription",
    });

  const formatted = value.length > 100 ? value.slice(0, 100) + "..." : value;

  return (
    <Link css={link} to={searchLink(prop("department", docData))} title={value}>
      {formatted}
    </Link>
  );
};

export default LegalLink;
