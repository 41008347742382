import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router";
import { Link } from "../../../components";
import { withWorkspaceId } from "../../../hoc";
import { actions, selectors } from "../../../store";
import AdvancedSearch from "../../../units/search.advanced";
import BasicSearch from "../../../units/search.basic";
import SearchMasthead from "./search-masthead";
import SearchHistory from "./SearchHistory";

export const Search = ({ workspaceID }) => {
  const dispatch = useDispatch();

  const { getSelectedDepartment, determineDeptHasEmptyDate } =
    selectors.workspaces;

  const deptCode = useSelector(getSelectedDepartment);
  const shouldFetchDeptDates = useSelector(determineDeptHasEmptyDate);

  const removeFetchedDocuments = (workspaceID) =>
    dispatch(actions.documents.removeFetchedDocuments({ workspaceID }));

  const fetchDepartmentDateRanges = (nextDeptCode) =>
    dispatch(
      actions.search.fetchDepartmentDateRanges({
        shouldFetch: shouldFetchDeptDates,
        department: nextDeptCode || deptCode,
      })
    );

  useEffect(() => {
    fetchDepartmentDateRanges();
    removeFetchedDocuments(workspaceID);
  }, []);

  return (
    <>
      <Helmet>
        <title>Search</title>
      </Helmet>
      <SearchMasthead />
      <ul className="search__types">
        <li className="search__type">
          <Link exact to="/" className="search__select-type">
            Quick Search
          </Link>
        </li>
        <li className="search__type" data-tourid="advancedSearchLink">
          <Link exact to="/search/advanced" className="search__select-type">
            Advanced Search
          </Link>
        </li>
      </ul>
      <Switch>
        <Route exact path="/" render={() => <BasicSearch />} />
        <Route
          path="/search/advanced"
          render={() => (
            <AdvancedSearch
              fetchDepartmentDateRanges={fetchDepartmentDateRanges}
            />
          )}
        />
      </Switch>
      <SearchHistory />
    </>
  );
};

export default withWorkspaceId(Search);
