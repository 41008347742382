import styled from "@emotion/styled";

const alignRight = `
  left: unset;
  right: 0;
`;

const alignTop = `
  top: -75px;
`;

const alignCenter = `
  top: -25px;
  left: 30px;
`;

export const Options = styled.ul`
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
  position: absolute;
  top: calc(100% + 2px);
  z-index: 999;

  ${(props) => {
    if (props.alignment === "top") return alignTop;
    if (props.alignment === "center") return alignCenter;
    if (props.alignment === "right") return alignRight;
  }}
`;
