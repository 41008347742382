import styled from "@emotion/styled";

export const Submit = styled.button`
  background: #006dd5;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
  display: block;
  font-size: 14px;
  margin: 32px auto 0;
  padding: 10px 32px;
`;
