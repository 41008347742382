import { css } from "@emotion/react";
import prop from "ramda/src/prop";
import { buttonStyle } from "../account-settings.subscriptions.common/_style.button";
import { FinishSubmissionInstructions } from "./application.submit.payment.finish";
import { SaveCardInstructions } from "./application.submit.payment.save-card";

export const commonCardStyles = css`
  background-color: #fff;
  border: 1px solid grey;
  margin: 10px;
  padding: 15px;
  border-radius: 4px;
  line-height: 1.5;
  color: #596e93;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: left;

  img {
    width: 30px;
    grid-area: icon;
    align-self: center;
  }

  a {
    grid-area: action;
    width: max-content;
    ${buttonStyle}
  }
`;

export const style = {
  addPayment: css`
    grid-area: add-payment;
  `,
  card: (isRecurringPlan) => css`
    ${commonCardStyles}
    ${isRecurringPlan &&
    `
      display: grid;
      grid-gap: 15px;
      grid-template-columns: 35px 1fr;
      grid-template-rows: 1fr 40px;
      grid-template-areas:
      "icon title"
      "icon action";
    `}
  `,
};

export const PaymentInfo = ({ hasSavedPayment, plan, copy }) => {
  const isFixedTerm = prop("fixedTerm", plan);
  const planIsFree = prop("amount", plan) === "$0.00";

  const isRecurringPlan = !planIsFree && !isFixedTerm;
  const gridButton = isRecurringPlan && !hasSavedPayment;

  return (
    <div css={[style.addPayment, style.card(gridButton)]}>
      {isRecurringPlan ? (
        <SaveCardInstructions hasSavedPayment={hasSavedPayment} />
      ) : (
        <FinishSubmissionInstructions type={plan.planCode} copy={copy} />
      )}
    </div>
  );
};
