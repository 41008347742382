import styled from "@emotion/styled";

export const Wrapper = styled.div`
  background: #ffffff;
  border-radius: 4px;
  width: 480px;

  & .checkbox {
    margin-top: 15px;
  }
`;
