import { css } from "@emotion/react";
import { styles } from "./styles";

const style = css`
  ${styles.button};
  background: #ffffff;
  border: 1px solid rgba(4, 29, 68, 0.1);
  color: #006dd5;
`;

const CancelButton = ({ text = "Decline", cancel }) => (
  <button css={style} type="button" onClick={cancel}>
    {text}
  </button>
);

export default CancelButton;
