import { css } from "@emotion/react";
import PropTypes from "prop-types";

const styles = {
  wrapper: css`
    padding: 10px 20px;
    background: #ffffff;
  `,
  dl: css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;

    & > * {
      border-bottom: 1px solid rgba(4, 29, 68, 0.15);
      padding: 10px 0;
    }

    & > dd {
      color: rgba(4, 29, 68, 0.6);
    }

    & > *:nth-last-of-type(-n + 2) {
      border: none;
    }
  `,
};

export const DocumentSummary = ({ doc, county }) => (
  <div css={styles.wrapper}>
    <dl css={styles.dl}>
      <dt>Document Number:</dt>
      <dd>{doc.docNumber}</dd>
      <dt>Recorded Date:</dt>
      <dd>{doc.recordedDate}</dd>
      <dt>County:</dt>
      <dd>{county}</dd>
    </dl>
  </div>
);

DocumentSummary.displayName = "DocumentSummary";

DocumentSummary.propTypes = {
  doc: PropTypes.object,
  county: PropTypes.string,
};
