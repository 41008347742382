import styled from "@emotion/styled";

export const SignOutBody = styled.div`
  align-items: center;
  background-color: #ffffff;
  border: 1px solid rgba(4, 29, 68, 0.15);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;

  & img {
    height: 130px;
    margin-bottom: 10px;
    width: 130px;
  }
`;
