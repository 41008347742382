import { css } from "@emotion/react";
import complement from "ramda/src/complement";
import isEmpty from "ramda/src/isEmpty";
import { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ErrorBar, LoadingSpinner } from "../../components";
import { Button } from "../../components/Modal";
import { hasError, Status } from "../../constants";
import { useLogin } from "../../hooks";
import { useFraudAlertTerms } from "../../hooks/termsAndConditions";
import { actions, selectors } from "../../store";
import { isAbsent } from "../../utils";
import { RadioSet } from "../Fields";
import { Confirmed } from "./Confirmed";
import { DeedNumber } from "./DeedNumber";
import { NotificationInfo } from "./notification-info";
import { Section } from "./Section";
import { Title } from "./Title";
import { useMultiSelectRadios } from "./use-multiselect-radios";

const style = {
  criteria: css`
    padding: 26px 0 36px 0;

    & > * + * {
      margin-top: 26px;
    }

    section h2 {
      font-weight: 600;
      margin-bottom: 10px;
    }

    li + li {
      margin-top: 5px;
    }

    .checkbox__controls {
      align-items: unset;
    }

    .checkbox__label {
      font-size: 16px;
      font-weight: 400;
      white-space: unset;
    }
  `,
  form: (shouldThrash) => css`
    background: #ffffff;
    border: 1px solid #d8dde6;
    border-radius: 4px;
    color: #52607a;
    width: ${shouldThrash ? "656px" : "655px"};
    padding: 30px;

    strong {
      color: #0b1d41;
      font-weight: 600;
    }

    & button {
      margin-left: 30px;
    }
  `,
  errorContainer: css`
    background-color: #ffffff;
    width: 400px;
    border-radius: 4px;
    padding: 30px;
    color: #52607a;
    line-height: 1.5;

    * {
      margin-bottom: 20px;
    }
  `,
};

const isNotEmpty = complement(isEmpty);

const information =
  "Receive email alerts any time your personal name, business name, or property address is used in a Real Property filing within the county.";

const chooseOne = "Choose one or more of the following as your alert criteria:";

const defaultError = "Error creating Property Alert, please try again.";

const FraudAlert = ({ cancel, errorMsg = defaultError }) => {
  const dispatch = useDispatch();
  const login = useLogin();
  const { inputProps, shouldDisplayRadios, radioOptions, shouldMergeCriteria } =
    useMultiSelectRadios();
  const [shouldThrash, setShouldThrash] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const [selected, setSelected] = useState({});

  useLayoutEffect(() => {
    setShouldThrash(!shouldThrash);
  }, [selected]);

  const { display: displayTerms, hasAccepted } = useFraudAlertTerms({
    submit: (e) => {
      setShowTerms(false);
      setAcceptedTerms(true);
      createFraudAlert(shouldMergeCriteria(e));
    },
    cancel: () => {
      setShowTerms(false);
      cancel();
    },
  });

  const { docPreview, workspaces, fraudAlert } = selectors;
  const docData = useSelector(docPreview.getDocumentData);
  const department = useSelector(workspaces.getSelectedDepartment);
  const criteria = useSelector(fraudAlert.getCriteria);
  const submissionStatus = useSelector(fraudAlert.getSubmissionStatus);

  const set = setSelected.bind(this);
  const hasSelections = isNotEmpty(selected);
  const { Loading, Error } = Status;
  const isLoading = submissionStatus === Loading;

  const createFraudAlert = async (shouldMergeCriteria) => {
    const payload = { department, fields: { ...selected }, type: "default" };

    if (shouldMergeCriteria) {
      payload.type = "mergeCriteria";
    }

    await dispatch(actions.fraudAlert.create(payload));

    setSubmitted(true);
  };

  useEffect(() => {
    dispatch(actions.fraudAlert.fetchCriteria({ id: docData.id }));
  }, []);

  useEffect(() => {
    setError(submissionStatus === Error);
  }, [submissionStatus]);

  useEffect(() => {
    if (error) setSelected({});
  }, [error]);

  if (!login.loggedIn) return login.do();

  if ((!acceptedTerms && hasAccepted === null) || isAbsent(criteria)) {
    return <LoadingSpinner />;
  }

  if (showTerms) return <div>{displayTerms()}</div>;
  if (hasError(criteria.status)) {
    return (
      <div css={style.errorContainer}>
        <Title>Error</Title>
        <p>
          Unfortunately there has been an error in setting up this property
          alert. Please try again later. If the issue persists, contact support.
        </p>
        <Button onClick={cancel} text="Close" />
      </div>
    );
  }

  const showConfirmation =
    (acceptedTerms || hasAccepted) && submitted && !isLoading && !error;

  const submit = async (e) => {
    e.preventDefault();

    const shouldMerge = shouldMergeCriteria(e);
    hasAccepted ? createFraudAlert(shouldMerge) : setShowTerms(true);
  };

  const selectionCriteria = criteria.data.map(({ title, data, key }) => (
    <Section
      key={key}
      title={title}
      data={data}
      name={key}
      set={set}
      selected={selected}
    />
  ));

  return (
    <form css={style.form(shouldThrash)} onSubmit={submit}>
      {(acceptedTerms || showConfirmation) && (
        <Confirmed close={cancel} isModal={true} />
      )}
      {!acceptedTerms && !showConfirmation && (
        <>
          <Title>Create a Property Alert</Title>

          <div css={style.criteria}>
            <p>{information}</p>
            <p>
              <strong>{chooseOne}</strong>
            </p>
            {selectionCriteria}

            {shouldDisplayRadios(selected) && (
              <RadioSet inputProps={inputProps} radioOptions={radioOptions} />
            )}
          </div>

          <NotificationInfo />
          {error && submitted && <ErrorBar error={errorMsg} />}
          <DeedNumber doc={docData} />
          <Button onClick={cancel} text="Cancel" />
          <Button
            type="submit"
            disabled={!hasSelections || isLoading}
            text="Create Property Alert"
          />
        </>
      )}
    </form>
  );
};

export default FraudAlert;
