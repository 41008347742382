import { Link } from "react-router-dom";
import link from "./_summary.link";
import { styles } from "./marginal-reference.style";

const MarginalReferenceUrls = ({ data = [] }) => {
  return data.map((item) => {
    if (typeof item === "string") {
      return (
        <span css={styles.standaloneText} key={item}>
          {item}
        </span>
      );
    }

    const { instrumentNumber, id, docTypeDesc, recordedDate } = item;

    return (
      <div key={id} css={styles.block}>
        <Link css={link} to={`/doc/${id}`}>
          {`Instrument Number: ${instrumentNumber}`}
        </Link>
        <span css={styles.uppercaseLabel}>{docTypeDesc}</span>
        <span css={styles.label}>{recordedDate}</span>
      </div>
    );
  });
};

export default MarginalReferenceUrls;
