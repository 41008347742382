import pathOr from "ramda/src/pathOr";
import { useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { RadioSet } from "../../components/Fields";
import { isNotNil } from "../../utils";
import {
  getRadioOptions,
  getNumPages,
  checkInRange,
  formatPageSelections,
} from "./page-selection.utils";

const PageSelection = (props) => {
  const [radioValue, setRadioValue] = useState("all");
  const [inputValue, setInputValue] = useState("");
  const [hasError, setHasError] = useState(false);

  const updateRadioValue = (event) => {
    const { value } = event.target;
    setRadioValue(value);
    broadcastSelectedPages({ radio: value });
  };

  const updateHasError = (hasE) => {
    setHasError(hasE);
    hasE ? props.onError() : props.onClearError();
  };

  const updateInputValue = (event) => {
    const value = pathOr("", ["target", "value"], event);
    const pageLimitExceeded = getNumPages(value) > props.totalPages;
    const outOfRange = !checkInRange(props.totalPages, value);

    if (pageLimitExceeded || outOfRange) {
      updateHasError(true);
      setInputValue(value);
    } else {
      updateHasError(false);
      setInputValue(value);
      broadcastSelectedPages({ input: value });
    }
  };

  const broadcastSelectedPages = ({ radio, input }) => {
    const inputVal = isNotNil(input) ? input : inputValue;
    const radioVal = isNotNil(radio) ? radio : radioValue;

    if (radioVal === "all") {
      props.onChange("all");
    } else if (radioVal === "custom" && inputVal) {
      props.onChange(formatPageSelections(inputVal));
    } else if (radioVal === "currentPage") {
      props.onChange(formatPageSelections(String(props.currentPage)));
    }
  };

  const disabled = props.disabled || props.totalPages === 1;

  const localProps = {
    radio: {
      disabled: disabled,
      radioOptions: getRadioOptions(props.currentPage),
      inputProps: {
        value: radioValue,
        onChange: updateRadioValue,
      },
    },
    input: {
      type: "text",
      className: "form-field__input",
      placeholder: "e.g. 2-4, 6, 8",
      disabled: disabled || radioValue !== "custom",
      value: inputValue,
      onChange: updateInputValue,
      debounceTimeout: 500,
    },
  };

  if (props.itemCount > 1) return null;

  return (
    <>
      <div className="checkout__input-wrap">
        <RadioSet {...localProps.radio} />
        <div className="checkout__page-input">
          <DebounceInput {...localProps.input} />
        </div>
      </div>
      {hasError && (
        <div className="error-message">Values must be within page limit.</div>
      )}
    </>
  );
};

export default PageSelection;
