import { css } from "@emotion/react";
import { parse } from "query-string";
import compose from "ramda/src/compose";
import curry from "ramda/src/curry";
import prop from "ramda/src/prop";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { actions } from "../../../../../store";
import {
  getNewSearchURL,
  makeAllPartySearchValue,
  stripHighlights,
} from "../../../../../utils";

const parseLocation = compose(parse, prop("search"));

const styles = {
  anchor: css`
    color: #006dd5;
    cursor: pointer;
    margin: 0;
    padding: 0;
    background: 0;
    text-align: left;
    font-weight: bold;
    font-size: 0.8rem;
  `,
  container: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
  `,
};

const makeUrl = curry((query, key, value) => {
  return getNewSearchURL({ query, key, value });
});

export const ToolTip = ({
  data,
  columnKey,
  partyFields,
  location,
  openPreview,
}) => {
  const dispatch = useDispatch();
  const query = parseLocation(location);
  const columnIs = (key) => columnKey === key;

  const makeLink = curry((i, link, text) => {
    const onClick = () => dispatch(actions.tooltip.hide());
    return (
      <Link
        key={i}
        css={styles.anchor}
        to={link}
        onClick={onClick}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    );
  });

  return (
    <div css={styles.container}>
      {data.map((term, i) => {
        const makeToolTipLink = makeLink(i);
        const makeQueryUrl = makeUrl(query);

        if (columnIs("parcel")) return <p key={i}>{term}</p>;

        if (columnIs("marginalReferences")) {
          return makeToolTipLink(term.link, term.text);
        }

        if (columnIs("legalDescription")) {
          const legalSearch = makeQueryUrl(
            "legalDescription",
            `"${stripHighlights(term)}"`
          );
          return makeToolTipLink(legalSearch, term);
        }

        if (term.includes("See document for full list")) {
          return (
            <button key={i} onClick={openPreview} css={styles.anchor}>
              {term}
            </button>
          );
        }

        const makeValue = makeAllPartySearchValue;
        const partySearchValue = makeValue({ partyFields, term });
        const partySearch = makeQueryUrl("parties", partySearchValue);

        return makeToolTipLink(partySearch, term);
      })}
    </div>
  );
};
