import { Cart } from "@kofile/icons";

const EmptyCart = () => (
  <section className="empty-cart">
    <Cart id="cart-icon" className="empty-cart__icon" />
    <h3 className="empty-cart__header">Your Shopping Cart is Empty</h3>
    <p className="empty-cart__description">
      To add documents to your cart, search for documents and choose “Add to
      Cart” from the search results page.
    </p>
  </section>
);

EmptyCart.displayName = "EmptyCart";

export default EmptyCart;
