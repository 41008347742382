import { css } from "@emotion/react";
import compose from "ramda/src/compose";
import filter from "ramda/src/filter";
import prop from "ramda/src/prop";
import { useSelector } from "react-redux";
import { matchPath } from "react-router";
import { useLocation } from "react-router-dom";
import { hasLoaded } from "../../../constants";
import { selectors } from "../../../store";
import NavTab from "./nav.tab";
import { useCanUserPurchaseSubscription } from "./use-can-user-purchase-subscriptions";

const styles = {
  wrapper: css`
    background: rgba(4, 29, 68, 0.1);
    color: rgba(4, 29, 68, 1);
    width: 250px;

    @media print {
      display: none;
    }
  `,
  header: css`
    font-size: 16px;
    height: 16px;
    line-height: 1;
    padding: 20px;
    padding-right: 0;
    text-transform: uppercase;
    width: 200px;
  `,
};

const isActive = (location, currentPath, path) => {
  if (currentPath === "/account" && path === "/profile") {
    return true;
  }

  return Boolean(
    matchPath(location.pathname, {
      path: `/account${path}`,
      exact: false,
      strict: false,
    })
  );
};

const getNavItems = ({
  subscriptionEnabled,
  fraudAlertEnabled,
  useNCourt,
  organizationEnabled,
}) => [
  { to: "/profile", label: "Profile", isEnabled: true, icon: "profile" },
  { to: "/password", label: "Password", isEnabled: true, icon: "password" },
  { to: "/notepads", label: "Notepads", isEnabled: false, icon: "profile" },
  {
    to: "/history",
    label: "Order History",
    isEnabled: true,
    icon: "order-history",
  },
  {
    to: "/subscription",
    label: "Subscription",
    isEnabled: subscriptionEnabled,
    icon: "subscription",
  },
  {
    to: "/organization",
    label: "Organization",
    isEnabled: organizationEnabled,
    icon: "organization",
  },
  {
    to: "/payment",
    label: "Payment Info",
    isEnabled: !useNCourt,
    icon: "credit-card",
  },
  {
    to: "/property-alert",
    label: "Property Alerts",
    isEnabled: fraudAlertEnabled,
    icon: "fraud-alert",
  },
];

const prepareNavItems = compose(filter(prop("isEnabled")), getNavItems);

export const SettingsNav = ({ organizationEnabled }) => {
  const location = useLocation();
  const { data, status } = useCanUserPurchaseSubscription();

  const { assets, configuration } = selectors;
  const useNCourt = useSelector(configuration.isNCourtEnabled);
  const allAssets = useSelector(assets.getAll);
  const fraudAlertEnabled = useSelector(configuration.isFraudAlertEnabled);
  const subscriptionEnabled = hasLoaded(status) && data;

  const props = {
    assets: allAssets,
    subscriptionEnabled,
    fraudAlertEnabled,
    useNCourt,
    organizationEnabled,
  };

  const navTabs = prepareNavItems(props).map((item, i) => (
    <NavTab
      key={i}
      {...item}
      active={isActive(location, location.pathname, item.to)}
    />
  ));

  return (
    <div css={styles.wrapper}>
      <h1 css={styles.header}>account settings</h1>
      {navTabs}
    </div>
  );
};

export default SettingsNav;
