import styled from "@emotion/styled";

export const Amount = styled.div`
  color: #fff;
  font-family: inherit;
  font-size: 15px;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.7);
  border-radius: 3px;
  display: flex;
  height: 30px;
  justify-content: center;
  width: 28px;
`;
