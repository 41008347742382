import classnames from "classnames";
import PropTypes from "prop-types";
import complement from "ramda/src/complement";
import defaultTo from "ramda/src/defaultTo";
import is from "ramda/src/is";
import isEmpty from "ramda/src/isEmpty";
import isNil from "ramda/src/isNil";
import propOr from "ramda/src/propOr";
import { DebounceInput } from "react-debounce-input";
import {
  MonthPicker,
  CheckboxField,
  RadioSet,
  DocumentNumberInput,
  InlineDatePicker,
  PasswordInput,
} from "../../../components/Fields";
import Select from "../../../components/Select";
import TokenizedNestedSelect from "../../../components/TokenizedNestedSelect";
import { default as TokenizedSelect } from "../../../components/TokenizedSelect";
import { useDateRangeMetaLabel } from "../../../hooks/use-date-range-meta-label";
import { Autocomplete } from "../../../units/common/input.autocomplete";
import { Dropdown } from "../../../units/common/input.dropdown";
import { noop } from "../../../utils";

const defaultToOff = defaultTo("off");
const isNotEmpty = complement(isEmpty);
const isString = is(String);

export const Field = (props) => {
  const titleRow = props.noLabel
    ? ""
    : classnames("form-field__title-row", "form-field__input-title");

  const errorClass = classnames("form-field__error-message", {
    "form-field__show": props.error !== "" && props.error,
  });

  const inputClass = classnames("form-field__input", {
    "form-field__input-error": props.error !== "" && props.error,
  });

  const textareaClass = classnames(inputClass, "form-field__textarea");

  const inputProps = {
    id: props.id,
    value: props.value,
    label: props.label,
    "aria-label": props.ariaLabel,
    placeholder: props.placeholder,
    autoFocus: props.autoFocus || false,
    onChange: (event) => props.onChange(event.target.value),
    disabled: props.disabled || false,
    autoComplete: defaultToOff(props.autoComplete),
  };

  const ComponentAtoms = {
    TokenizedNestedSelect: () => (
      <TokenizedNestedSelect
        {...props}
        selectedItems={
          isString(props.value) && isNotEmpty(props.value)
            ? props.value.split(",")
            : []
        }
      />
    ),
    Select: () => (
      <Select
        {...props}
        className="select"
        onChange={(obj) => props.onChange(obj.value)}
      />
    ),
    StringInput: () => (
      <input type="text" className={inputClass} {...inputProps} />
    ),
    NumberInput: () => (
      <input type="number" className={inputClass} {...inputProps} />
    ),
    PositiveNumberInput: () => (
      <input type="number" min="0" className={inputClass} {...inputProps} />
    ),
    EmailInput: () => (
      <input type="email" className={inputClass} {...inputProps} />
    ),
    DebounceInput: () => (
      <DebounceInput
        className={inputClass}
        debounceTimeout={props.debounceTimeout}
        {...inputProps}
      />
    ),
    PasswordInput: () => (
      <PasswordInput
        showPasswordOption={props.showPasswordOption}
        inputClass={inputClass}
        {...inputProps}
      />
    ),
    TextArea: () => <textarea {...inputProps} className={textareaClass} />,
    InlineDatePicker: () => (
      <InlineDatePicker
        {...props}
        error={!isEmpty(props.error)}
        className="form-field__input"
      />
    ),
    TokenizedSelect: () => (
      <TokenizedSelect
        {...props}
        separator="OR"
        updateFieldError={props.updateFieldError}
        onChange={(values) => props.onChange(values.join(","))}
      />
    ),
    InputRange: () => (
      <DocumentNumberInput {...props} inputProps={inputProps} />
    ),
    RadioSet: () => (
      <RadioSet
        {...props}
        radioOptions={props.radioOptions}
        inputProps={inputProps}
      />
    ),
    Checkbox: () => <CheckboxField {...props} inputProps={inputProps} />,
    MonthPicker: () => <MonthPicker {...props} />,
    Autocomplete: () => <Autocomplete {...props} inputProps={inputProps} />,
    Dropdown: () => <Dropdown {...props} inputProps={inputProps} />,
    LandCorner: () => {
      const serializedOptions = props.options
        .map(({ label, value }) => `${label}:${value}`)
        .join(",");

      return (
        <select-land-corners
          label={props._label}
          options={serializedOptions}
          explanation={props.explanation}
          department={props.department.code}
          selected={props.value}
        />
      );
    },
  };

  const inputType = propOr(null, props.type, ComponentAtoms);
  const dateRangeMetaLabel = useDateRangeMetaLabel();

  if (isNil(inputType)) return null;

  const showDateMetaLabelForFields = new Set(["recordedDateRange"]);

  return (
    <div className="form-field__full-wrap">
      {props.label && (
        <label id={`${props.id}-label`} htmlFor={props.id} className={titleRow}>
          {props.label}
          {props.tooltip}
        </label>
      )}
      {showDateMetaLabelForFields.has(props.id) && (
        <p className="form-field__certified-date">{dateRangeMetaLabel}</p>
      )}
      {props.type === "InlineDatePicker" && (
        <p style={{ fontSize: "0.8rem", paddingBottom: "3px" }}>
          Date Format: MM/DD/YYYY
        </p>
      )}
      {inputType()}
      <span className={errorClass}>{props.error}</span>
    </div>
  );
};

Field.displayName = "Field";

Field.defaultProps = {
  onChange: noop,
  value: "",
};

Field.propTypes = {
  error: PropTypes.string,
  type: PropTypes.string,
  ariaLabel: PropTypes.string,
  label: PropTypes.string,
  tooltip: PropTypes.node,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({
      startDate: PropTypes.instanceOf(Date),
      endDate: PropTypes.instanceOf(Date),
    }),
  ]),
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  autoFocus: PropTypes.bool,
  showPasswordOption: PropTypes.bool,
  certifiedDate: PropTypes.instanceOf(Date),
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  radioOptions: PropTypes.arrayOf(PropTypes.object),
  noLabel: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Field;
