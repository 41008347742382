import { css } from "@emotion/react";
import { styles } from "./styles";

const style = css`
  ${styles.button};
  background: #006dd5;
  border: 1px solid rgba(4, 29, 68, 0.1);
  color: #ffffff;
`;

const SubmitButton = ({ text = "Accept", submit }) => (
  <button css={style} type="submit" onClick={submit}>
    {text}
  </button>
);

export default SubmitButton;
