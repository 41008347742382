import styled from "@emotion/styled";

export const CardArea = styled.button`
  background-color: #fff;
  border: 1px solid rgba(4, 29, 68, 0.15);
  border-radius: 4px;
  color: rgba(4, 29, 68, 0.8);
  cursor: pointer;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  padding: 10px 20px 10px 12px;
  width: 100%;
`;
