import { css } from "@emotion/react";
import { Plus } from "@kofile/icons";
import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import { tabsHeight } from "../../measurements";
import { actions, selectors } from "../../store";
import WorkspaceTabs from "./workspaces.tabs";

const makeGoTo = (push, dispatch) => (path) => () => dispatch(push(path));

const workspace = (theme) => css`
  background-color: #243f8a;
  grid-area: workspaces;
  display: flex;
  height: ${tabsHeight};
  padding-right: 4px;

  @media print {
    display: none;
  }

  @media screen and (max-width: ${theme.breakpoints.sm}px) {
    display: none !important;
  }
`;

const Workspaces = ({ hide = false }) => {
  const dispatch = useDispatch();
  const goTo = makeGoTo(push, dispatch);
  const workspaces = useSelector(selectors.workspaces.getWorkspaceList);
  const close = (id) => () => dispatch(actions.workspaces.removeTab(id));

  const activeWorkspaceID = useSelector(
    selectors.workspaces.getActiveWorkspaceID
  );

  if (hide) return null;

  return (
    <div id="workspaces" css={workspace}>
      <button
        type="button"
        className="workspaces__menu"
        data-tourid="addWorkspace"
        onClick={goTo("/")}
        aria-label="add workspace"
      >
        <Plus
          id="workspaces-icon-add"
          className="workspaces__menu-control-icon"
        />
      </button>
      <WorkspaceTabs
        close={close}
        workspaces={workspaces}
        key={activeWorkspaceID}
      />
    </div>
  );
};

export default Workspaces;
