import classnames from "classnames";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { selectors } from "../../store";
import { noop } from "../../utils";
import HiddenLabel from "./_hiddenLabel";

export const ClearableInput = ({
  ariaDescription,
  ariaDescriptionId,
  className,
  dataTestId,
  dataTourId,
  disabled = false,
  onChange,
  onKeyDown = noop,
  placeholder,
  value,
}) => {
  const clearInput = (e) => {
    e.preventDefault();
    onChange({ target: { value: "" } });
  };

  const clearIcon = useSelector(
    selectors.assets.getImg("icon_clear_search.svg")
  );

  const classNames = classnames("clearable-input", className);

  return (
    <div className={classNames}>
      <input
        aria-describedby={ariaDescriptionId}
        placeholder={placeholder}
        className={className}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        autoComplete="off"
        disabled={disabled}
        data-testid={dataTestId}
        data-tourid={dataTourId}
      />
      {ariaDescriptionId && ariaDescription && (
        <HiddenLabel id={ariaDescriptionId}>{ariaDescription}</HiddenLabel>
      )}
      {value && value.length && (
        <button className="clearable-input__button" onClick={clearInput}>
          <img src={clearIcon} alt="clear search text icon" />
        </button>
      )}
    </div>
  );
};

ClearableInput.displayName = "ClearableInput";

ClearableInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  className: PropTypes.string,
};

export default ClearableInput;
