import { css } from "@emotion/react";
import { useSelector } from "react-redux";
import { selectors } from "../../store";
import { isAbsent } from "../../utils";

const style = css`
  padding-bottom: 20px;
  color: #041d44;

  a {
    text-decoration: underline;
  }
`;

const formatContactMethods = (prefs) => {
  if (isAbsent(prefs)) return "email";
  if (prefs.length === 1) return `email and ${prefs[0]}`;

  const lastItem = prefs[prefs.length - 1];
  const otherItems = prefs.slice(0, -1);
  return `email, ${otherItems.join(", ")} and ${lastItem}`;
};

export const NotificationInfo = () => {
  const { getNotificationPreferences } = selectors.user;
  const preferences = useSelector(getNotificationPreferences);

  if (isAbsent(preferences)) return null;

  const contactMethods = formatContactMethods(preferences);

  return (
    <p css={style.notification}>
      You’ll receive alerts via {contactMethods}. Visit your{" "}
      <a href="/">account settings</a> to update your notification preferences.
    </p>
  );
};
