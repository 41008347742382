/* global htmx -- Utilizes Htmx-powered fragments from API */

import { css } from "@emotion/react";
import { useEffect } from "react";
import assoc from "ramda/src/assoc";
import dissoc from "ramda/src/dissoc";
import pipe from "ramda/src/pipe";
import pick from "ramda/src/pick";
import lensProp from "ramda/src/lensProp";
import set from "ramda/src/set";
import over from "ramda/src/over";
import cond from "ramda/src/cond";
import always from "ramda/src/always";
import map from "ramda/src/map";

const typeLens = lensProp("type");
const pageLens = lensProp("selectedPages");
const deptLens = lensProp("department");
const deliveryMethodLens = lensProp("deliveryMethod");
const attachmentsLens = lensProp("attachments");

const convertType = (type) => {
  return {
    document: "docimage",
    export: "indexpage",
  }[type];
};

const moveProp = (oldName, newName) => {
  return (obj) => pipe(assoc(newName, obj[oldName]), dissoc(oldName))(obj);
};

const style = css`
  background: white;
  width: 556px;
  padding: 1rem;

  & > loading-indicator {
    display: block;
    width: 100px;
    margin: 0 auto;
  }
`;

const stripAttachments = pick(["id", "totalPages"]);

const stripItem = ({ type, department }) =>
  cond([
    // we're purchasing a normal document
    [
      always(type === "document"),
      pipe(
        pick([
          "imageId",
          "docId",
          "pageCount",
          "docTypeCode",
          "instrumentNumber",
          "attachments",
          "hasTranscription",
          "hasTranslation",
        ]),
        moveProp("imageId", "id"),
        moveProp("docTypeCode", "docType"),
        moveProp("pageCount", "totalPages"),
        set(typeLens, convertType(type)),
        set(pageLens, "all"),
        set(deptLens, department),
        set(deliveryMethodLens, "download"),
        over(attachmentsLens, map(stripAttachments))
      ),
    ],

    // we're purchasing a search export results page (indexpage)
    [
      always(type === "export"),
      pipe(
        (obj) => {
          obj.metadata = {
            totalPrice: obj.totalPrice,
            subtotal: obj.subtotal,
            convenienceFee: obj.convenienceFee,
            rows: obj.rows,
            id: obj.id,
            filePath: obj.filePath,
          };

          return obj;
        },
        pick(["pages", "metadata"]),
        set(typeLens, convertType(type)),
        set(pageLens, "all"),
        moveProp("pages", "totalPages")
      ),
    ],
  ]);

export const RequestPurchase = ({ department, type, item, certifiedCopy }) => {
  useEffect(() => {
    htmx.ajax("POST", "/cb/request-to-purchase", {
      target: "#purchase-request-container",
      values: {
        what: stripItem({ type, department })(item),
        when: "now",
        department,
        wants_certified_copy: certifiedCopy === true ? "yes" : "no",
      },
    });
  }, [item]);

  return (
    <div id="purchase-request-container" css={style}>
      <loading-indicator class="htmx-indicator"></loading-indicator>
    </div>
  );
};
