import { css } from "@emotion/react";

const style = {
  button: css`
    color: #fff;
    background: #1c7dd9;
    padding: 8px 16px;
    font-size: 14px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;

    & > * + * {
      margin-left: 8px;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  `,
  icon: css`
    height: 20px;
    width: 20px;
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(84deg)
      brightness(101%) contrast(101%);
  `,
  copy: css`
    white-space: nowrap;
  `,
};

export const Button = (props) => {
  const { onClick, disabled, className, icon, children, type } = props;
  const dataTestId = props["data-testid"];

  return (
    <button
      data-testid={dataTestId}
      css={style.button}
      onClick={onClick}
      className={className}
      disabled={disabled}
      type={type}
    >
      {icon && <img src={icon} alt="printer" css={style.icon} />}
      <span css={style.copy}>{children}</span>
    </button>
  );
};

Button.defaultProps = {
  type: "button",
  onClick: () => {},
};
