import mergeDeepRight from "ramda/src/mergeDeepRight";

export const DEFAULT_CONFIG = {
  firstName: {
    id: "firstName",
    type: "StringInput",
    ariaLabel: "First Name",
    label: "First Name",
    placeholder: "First Name",
    fieldId: "first-name",
    autoFocus: true,
    validations: [
      {
        method: "isRequired",
      },
      {
        method: "matches",
        arg: /[^0-9]{1,128}/,
        error: "Input must be a valid first name",
      },
    ],
  },
  lastName: {
    id: "lastName",
    type: "StringInput",
    ariaLabel: "Last Name",
    label: "Last Name",
    placeholder: "Last Name",
    fieldId: "last-name",
    validations: [
      {
        method: "isRequired",
      },
      {
        method: "matches",
        arg: /[^0-9]{1,128}/,
        error: "Input must be a valid last name",
      },
    ],
  },
  phoneNumber: {
    id: "phoneNumber",
    type: "StringInput",
    ariaLabel: "Phone Number",
    label: "Phone Number",
    placeholder: "000-000-0000",
    fieldId: "phone-number",
    validations: [
      {
        method: "matches",
        arg: /^(\+\d{1,2}\s?)?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/,
        error: "Please enter a valid phone number (e.g., 123-456-7890)",
      },
    ],
  },
  contactByEmail: {
    id: "contactByEmail",
    type: "Checkbox",
    isChecked: true,
    ariaLabel: "contact by email",
    checkboxLabel: "Email",
    fieldId: "contact-by-email",
    disabled: true,
  },
  contactByPhone: {
    id: "contactByPhone",
    type: "Checkbox",
    isChecked: false, // need to make this update based on request
    ariaLabel: "contact by phone call",
    checkboxLabel: "Voice Phone Call",
    fieldId: "contact-by-phone",
  },
  contactByText: {
    id: "contactByText",
    type: "Checkbox",
    isChecked: false, // need to make this update based on request
    ariaLabel: "contact by text",
    checkboxLabel: "Text Message",
    fieldId: "contact-by-text",
  },
  consent: {
    id: "consent",
    type: "Checkbox",
    isChecked: false, // need to make this update based on request
    ariaLabel: "consent to contact by phone or text",
    checkboxLabel: "I consent to receiving Text SMS and/or Voice Calls",
    fieldId: "consent-to-contact",
  },
};

const getFormConfig = (values = {}) => mergeDeepRight(DEFAULT_CONFIG, values);

export default getFormConfig;
