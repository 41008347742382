import React from "react";
import { useSelector } from "react-redux";
import { selectors } from "../../../../store";
import defaultText from "./card.default-text";
import styles from "./styles";

export const CreateFraudAlertCard = ({ type, text = defaultText }) => {
  const { title, blurb, buttonText, buttonUrl } = text[type];
  const isName = type === "byName";

  const { getImg } = selectors.assets;
  const keywordIcon = useSelector(getImg("icon_fraud_landing_byName.svg"));
  const docIcon = useSelector(getImg("icon_fraud_nav.svg"));

  return (
    <React.Fragment>
      <img
        css={styles.card.icon(!isName)}
        src={isName ? keywordIcon : docIcon}
        alt={`create alert by ${isName ? "name" : "document"}`}
      />
      <h3 css={styles.card.heading}>{title}</h3>
      <p css={styles.card.blurb}>{blurb}</p>
      <a css={styles.card.link} href={buttonUrl}>
        <button css={styles.card.button}>{buttonText}</button>
      </a>
    </React.Fragment>
  );
};

export default CreateFraudAlertCard;
