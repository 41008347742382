import { css } from "@emotion/react";
import { Menu } from "@kofile/react-a11y-menu";
import pipe from "ramda/src/pipe";
import when from "ramda/src/when";
import { Fragment, useEffect } from "react";
import { LoadingSpinner, Button } from "../../../../components";
import { isLoading, isInit, hasLoaded } from "../../../../constants";
import { isPresent, isAbsent } from "../../../../utils";
import PreviousSearches from "./previous-searches";
import Toggle from "./toggle";
import { useClearSearchHistory } from "./use-clear-search-history";
import { useFetchSearchHistory } from "./use-fetch-search-history";

const style = {
  wrap: css`
    color: #354868;
    display: grid;
    place-items: center;
    font-size: 15px;
    margin: 20px auto;
    max-width: 780px;
    width: 100%;
  `,
  results: css`
    display: flex;
    flex-direction: column;
    width: 780px;
    align-items: stretch;
    margin: 10px 0;
    background: #ffffff;
    border-radius: 4px;
    border: 1px #e6e8ed solid;
    padding: 5px;
  `,
  button: css`
    font-size: 16px;
    align-self: center;
    margin: 10px 0;
  `,
  noResults: css`
    text-align: center;
    margin: 10px 0;
  `,
};

const SearchHistory = () => {
  const fetchSearchHistory = useFetchSearchHistory();
  const clearSearchHistory = useClearSearchHistory();
  const clear = () => clearSearchHistory.initiate();
  const { status, data, setData } = fetchSearchHistory;

  useEffect(() => {
    if (hasLoaded(clearSearchHistory.status)) setData([]);
  }, [clearSearchHistory.status]);

  const toggle = (onClick, isOpen) =>
    pipe(
      onClick,
      when(() => isOpen === false, fetchSearchHistory.initiate)
    );

  if (process.env.IS_CLIENT === "f") {
    return (
      <div css={style.wrap}>
        <Toggle />
      </div>
    );
  }

  return (
    <Menu css={style.wrap}>
      {({ menu, control, isOpen, item }) => (
        <Fragment>
          <Toggle {...control} onClick={toggle(control.onClick, isOpen)} />
          {isOpen && (
            <div css={style.results} {...menu}>
              {isInit(status) && <LoadingSpinner />}
              {isLoading(status) && <LoadingSpinner />}
              {hasLoaded(status) && (
                <Fragment>
                  {isAbsent(data) && (
                    <span {...item} css={style.noResults}>
                      No searches.
                    </span>
                  )}
                  {isPresent(data) && (
                    <Fragment>
                      <ul {...item}>
                        <PreviousSearches data={data} />
                      </ul>
                      <Button onClick={clear} {...item} css={style.button}>
                        Clear Search History
                      </Button>
                    </Fragment>
                  )}
                </Fragment>
              )}
            </div>
          )}
        </Fragment>
      )}
    </Menu>
  );
};

export default SearchHistory;
