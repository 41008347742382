import styled from "@emotion/styled";

export const TooltipContent = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  color: #ffffff;
  max-width: 380px;
  padding: 8px;
  width: 100%;
  text-align: center;
`;
