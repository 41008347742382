import classnames from "classnames";
import PropTypes from "prop-types";
import { Component } from "react";
import { connect } from "react-redux";
import { Route, withRouter } from "react-router";
import { Link as DomLink } from "react-router-dom";
import { selectors } from "../../store";
import { parseUrl } from "../../utils";

class Link extends Component {
  constructor(props) {
    super(props);

    this.linkMaker = ({ match }) => {
      const {
        children,
        dataTestId,
        dataTourId,
        anchorClass,
        role,
        "aria-label": ariaLabel,
      } = this.props;
      const to = this.resolveRoute(this.props.to);

      const className = classnames(this.props.className, {
        [`${this.props.className}--active`]: this.props.className && match,
      });

      const linkClass = anchorClass || "";

      return (
        <div
          className={className}
          data-testid={dataTestId}
          data-tourid={dataTourId}
        >
          <DomLink
            className={linkClass}
            to={to}
            role={role}
            aria-label={ariaLabel}
          >
            {children}
          </DomLink>
        </div>
      );
    };
  }

  resolveRoute(to) {
    const { workspaceID, openNewWorkspace } = this.props;
    const { pathname, search } = parseUrl(to);

    const newRoute = { pathname, search };

    if (!openNewWorkspace) {
      newRoute.state = { workspaceID };
    } else {
      newRoute.state = {};
    }

    return newRoute;
  }

  render() {
    const { exact, to } = this.props;

    return (
      <Route path={to} exact={exact}>
        {this.linkMaker}
      </Route>
    );
  }
}

Link.displayName = "Link";

Link.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  exact: PropTypes.bool.isRequired,
  workspaceID: PropTypes.string,
  openNewWorkspace: PropTypes.bool,
  dataTestId: PropTypes.string,
};

Link.defaultProps = {
  exact: false,
  openNewWorkspace: false,
};

const mapStateToProps = (state, ownProps) => ({
  workspaceID:
    ownProps.workspaceID || selectors.workspaces.getActiveWorkspaceID(state),
});

export default withRouter(connect(mapStateToProps)(Link));
