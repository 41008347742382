import { css } from "@emotion/react";

const label = css`
  color: rgba(4, 29, 68, 0.65);
  font-size: 14px;
`;

const uppercaseLabel = css`
  ${label}
  text-transform:uppercase;
`;

export const styles = {
  block: css`
    align-items: center;
    display: grid;
    font-size: 15px;
    grid-template-columns: repeat(3, minmax(min-content, 1fr));
    padding: 12px 8px !important;
    border-bottom: 1px solid rgba(4, 29, 68, 0.15);
    color: rgba(4, 29, 68, 0.65);
    &:last-of-type {
      border-bottom: none;
    }
  `,
  label,
  uppercaseLabel,
  standaloneText: css`
    ${uppercaseLabel}
    padding: 12px 8px !important;
  `,
};
