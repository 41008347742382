import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { selectors } from "../../store";
import { parse, stringify } from "query-string";

const presets = {
  registration: {
    copy: "Click on the verification link sent to your email to complete your registration.",
    link: "Resend Confirmation Email",
    title: "Check Your Email",
    url: "/cb/send-registration-verification-email",
  },
  password: {
    copy: "Click below send the password reset email",
    link: "Resend Confirmation Email",
    title: "Check Your Email",
    url: "/cb/send-password-reset-email",
  },
};

export const CheckEmail = (props) => {
  const location = useLocation();
  const preset = presets[props.preset] ?? presets["registration"];
  const mailIcon = useSelector(selectors.assets.getImg("icon_mail.svg"));
  const email = props.email ?? parse(location.search)?.email;
  const link = preset.url + "?" + stringify({ email });

  return (
    <section className="check-email">
      <img alt="mail-icon" src={mailIcon} />
      <h1 className="check-email__header">{preset.title}</h1>
      <p>{preset.copy}</p>
      <a
        hx-boost="true"
        hx-swap="none"
        href={link}
        className="cta"
        hx-push-url="false"
      >
        {preset.link}
        <loading-indicator style={{ width: "20px" }}></loading-indicator>
      </a>
    </section>
  );
};
