import styled from "@emotion/styled";
import { colors } from "../../utils";
import { lightGray } from "./style";

export default styled.button`
  background: #ffffff;
  border-bottom: 1px solid ${lightGray};
  border-right: 1px solid ${lightGray};
  border-top: 1px solid ${lightGray};
  box-sizing: border-box;
  color: #4e607b;
  cursor: pointer;
  height: 33px;
  padding: 7px;
  position: relative;
  width: 40px;

  & > svg,
  > img {
    height: 18px;
    width: 18px;
    filter: invert(35%) sepia(32%) saturate(411%) hue-rotate(177deg)
      brightness(95%) contrast(93%);
  }

  ${(props) => props.isActive && isActive}
  ${(props) => props.disabled && disabled}
  ${(props) => props.isNew && isNew}
  ${(props) => props.mirrorIcon && mirrorIcon}


  &:first-of-type {
    border-bottom-left-radius: 3px;
    border-left: 1px solid ${lightGray};
    border-top-left-radius: 3px;
  }

  > * + * {
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
  }

  @media screen and (max-width: 1242px) {
    height: 32px;
    width: 35px;
  }

  @media screen and (max-width: 1114px) {
    height: 32px;
    width: 30px;
  }
`;

const isActive = `
  background: ${colors.brightBlue};
  color: ${colors.white};

  & > img {
    filter: invert(100%);
  }
`;

const disabled = `
  cursor: not-allowed;
  opacity: 0.5;
`;

const isNew = `
  &::after {
    content: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTdweCIgaGVpZ2h0PSIxN3B4IiB2aWV3Qm94PSIwIDAgMTcgMTciIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDYyICg5MTM5MCkgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+c3RhcmJ1cnN0PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9InN0YXJidXJzdCIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPHBvbHlnb24gaWQ9IkZpbGwtMSIgZmlsbD0iI0ZFQ0IzNyIgcG9pbnRzPSI4LjUwMDA1OTI3IDAgNi4xNTA2MDA2OSAxLjYyMzM0MjE4IDMuMjQ2NjQwOTYgMS42MjMzNDIxOCAyLjM0OTM0MDA1IDQuMjUgMCA1Ljg3MzM0MjE4IDAuODk3NDE5NDQ5IDguNSAwIDExLjEyNjY1NzggMi4zNDkzNDAwNSAxMi43NSAzLjI0NjY0MDk2IDE1LjM3NjY1NzggNi4xNTA2MDA2OSAxNS4zNzY2NTc4IDguNTAwMDU5MjcgMTcgMTAuODQ5Mzk5MyAxNS4zNzY2NTc4IDEzLjc1MzM1OSAxNS4zNzY2NTc4IDE0LjY1MDY2IDEyLjc1IDE3IDExLjEyNjY1NzggMTYuMTAyNTgwNiA4LjUgMTcgNS44NzMzNDIxOCAxNC42NTA2NiA0LjI1IDEzLjc1MzM1OSAxLjYyMzM0MjE4IDEwLjg0OTM5OTMgMS42MjMzNDIxOCI+PC9wb2x5Z29uPgogICAgICAgIDxwb2x5Z29uIGlkPSJGaWxsLTEiIGZpbGw9IiNGRjg3MDAiIHBvaW50cz0iNy4zMzIyMjk4MiAzIDYuNDQ3NTU5NDUgNS4wODUzNjI2IDQuMDcwMDQ5MDUgNC42ODcxMTg4MiA0LjY3NTg0MzAzIDYuODU2NTg2MzkgMi41IDcuODMwNTg4MDggNC4zNjQ3NjMyIDkuMjU1NDc1NjcgMy4yMjE2NzM1MiAxMS4yMjk2Nzc3IDUuNjMzMTAzMSAxMS4zNjU2OTAyIDUuOTU5NDkyNzUgMTMuNTg2MjA4NCA3Ljk5Njc1NTQ2IDEyLjM4MTQzODQgOS42Njc3NzAxOCAxNCAxMC41NTI0NDA2IDExLjkxNDUxNSAxMi45Mjk5NTA5IDEyLjMxMjg4MTIgMTIuMzI0MTU3IDEwLjE0MzQxMzYgMTQuNSA5LjE2OTQxMTkyIDEyLjYzNTIzNjggNy43NDQ2NDY3NSAxMy43NzgxOTQ1IDUuNzcwMzIyMzEgMTEuMzY2NzY0OSA1LjYzNDE4NzMzIDExLjA0MDUwNzIgMy40MTM3OTE1NyA5LjAwMzI0NDU0IDQuNjE4NTYxNjMiPjwvcG9seWdvbj4KICAgIDwvZz4KPC9zdmc+");
    height: 17px;
    position: absolute;
    right: -8px;
    top: -8px;
    width: 17px;
  }
`;

const mirrorIcon = `
  & > svg,
  > img {
    transform: scale(-1, 1);
  }
`;
