import styled from "@emotion/styled";
import { push } from "connected-react-router";
import Tooltip from "rc-tooltip";
import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Popup } from "../../../../components";
import { selectors } from "../../../../store";
import { LinkContainer } from "./_link-container";
import { navLink } from "./_style.nav-link";

const Container = styled(LinkContainer)`
  & a {
    padding-right: 10px;
  }

  & img {
    margin-right: 6px;
  }
`;

const FraudAlert = ({ popupText = defaults }) => {
  const dispatch = useDispatch();
  const [hovering, setHovering] = useState(false);
  const { getImg } = selectors.assets;
  const starBadge = useSelector(getImg("icon_fraud_nav.svg"));
  let timeoutId;

  const mouseEnter = () => {
    setHovering(true);
    clearTimeout(timeoutId);
  };

  const mouseLeave = () => {
    timeoutId = setTimeout(() => setHovering(false), 250);
  };

  const navigate = () => void dispatch(push("/property-alert"));

  return (
    <Fragment>
      <Tooltip
        visible={hovering}
        placement="bottomLeft"
        overlay={
          <Popup
            {...popupText}
            onClear={mouseLeave}
            onMouseEnter={mouseEnter}
            onMouseLeave={mouseLeave}
            onClick={navigate}
          />
        }
      >
        <Container onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
          <Link to="/property-alert" workspaceID="property-alert" css={navLink}>
            <img src={starBadge} alt="property alert icon" />
            <div>Property Alert</div>
          </Link>
        </Container>
      </Tooltip>
    </Fragment>
  );
};

const defaults = {
  bodyText:
    "Get notified against potentially fraudulent claims or transactions regarding Real Property with our free service.",
  headerText: "GovOS Property Alerts",
};

export default FraudAlert;
