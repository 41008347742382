import { css } from "@emotion/react";
import compose from "ramda/src/compose";
import includes from "ramda/src/includes";
import propOr from "ramda/src/propOr";
import { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Form } from "../../../../../../components";
import { hasError, isLoading } from "../../../../../../constants";
import { withModal } from "../../../../../../hoc";
import { selectors } from "../../../../../../store";
import { isPresent } from "../../../../../../utils";
import { SubmitButton } from "../../button.submit";
import { FormContainer } from "../../form.container";
import { SettingsPane } from "../../settings-pane";
import { resetEmailContainer } from "../Payment/_settings-profile";
import getFormConfig from "./formConfig";
import RemovePhoneWarning from "./modal.remove-phone";
import PropertyAlertContactSettings from "./property-alert-contact-settings";
import { useUpdateUserInfo } from "./use-update-user-info";

const styles = {
  inputWrapper: css`
    margin-bottom: 16px;
  `,
  contactWrapper: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;
  `,
  formError: css`
    color: red;
    font-size: 1rem;
    padding-top: 20px;
    height: 20px;
  `,
  email: css`
    font-weight: 600;
    font-size: 0.9rem;
  `,
};

const initializer = ({ user }) => {
  return Form.createInitialState(getFormConfig({}), user);
};

export const Profile = (props) => {
  const { formState, updateFormValue, updateFormError, modal } = props;
  const [formError, setFormError] = useState("");

  const { initiate, status, error } = useUpdateUserInfo();
  const { configuration, user: userConfig } = selectors;
  const user = useSelector(userConfig.getUserDetails);
  const fraudAlert = useSelector(configuration.getFraudAlert);
  const alertMethods = propOr([], "alertMethods", fraudAlert);

  const formActions = { onChange: updateFormValue };
  const submitButtonText = isLoading(status) ? "Loading..." : "Update Profile";
  const formConfig = getFormConfig(formState);
  const form = Form.buildForm(formConfig, formState, formActions);
  const resetEmailLink = `/cb/send-registration-verification-email?email=${user?.email}`;
  const countyUsesPhone =
    includes("sms", alertMethods) || includes("phone", alertMethods);

  const getBool = (key) =>
    formState[key].value === "" ? false : formState[key].value;

  const handleSubmit = (event) => {
    setFormError("");
    event.preventDefault();
    if (!Form.checkIfFormValid(formConfig, formState, updateFormError)) return;

    const anyPhoneContact =
      getBool("contactByPhone") === true || getBool("contactByText") === true;

    if (anyPhoneContact && getBool("consent") === false) {
      setFormError("You must consent to receive alerts by phone or text.");
      return;
    }

    const needsWarning =
      user?.phoneNumber &&
      formState.phoneNumber.value === "" &&
      fraudAlert?.enabled &&
      countyUsesPhone;

    const updateUserInfo = () => {
      const methods = ["email"];
      if (getBool("contactByPhone")) methods.push("phone");
      if (getBool("contactByText")) methods.push("sms");

      const payload = {
        phoneNumber: formState.phoneNumber.value,
        firstName: formState.firstName.value,
        lastName: formState.lastName.value,
        consentToContact: getBool("consent"),
        alertMethods: methods,
      };

      if (payload.phoneNumber === "") {
        payload.phoneNumber = null;
      }

      initiate(payload);
    };

    if (needsWarning) {
      modal.open(
        RemovePhoneWarning({
          close: modal.close,
          updateUserInfo,
          status,
          error: error?.reason?.message || "",
          actionCopy: isLoading(status) ? "Loading..." : "Remove Phone Number",
        })
      );
    } else {
      updateUserInfo();
    }
  };

  useEffect(() => {
    if (hasError(status) && error?.reason?.message) {
      setFormError(error?.reason?.message);
    }
  }, [error, status]);

  useEffect(() => void htmx.process(document.body), []);

  return (
    <SettingsPane title="Profile">
      <form onSubmit={handleSubmit}>
        <FormContainer>
          <div css={styles.inputWrapper}>{form.firstName}</div>
          <div css={styles.inputWrapper}>{form.lastName}</div>
          <div css={styles.inputWrapper}>{form.phoneNumber}</div>
          <div css={[styles.inputWrapper, styles.email]}>Email</div>
          <div css={styles.inputWrapper}>{user?.email}</div>

          {!user?.isVerified && (
            <div css={resetEmailContainer}>
              <div>Email has not been verified</div>
              <a
                hx-push-url="false"
                hx-swap="none"
                hx-boost="true"
                href={resetEmailLink}
                className="cta"
              >
                Resend Verification Email
                <loading-indicator
                  style={{ width: "20px" }}
                ></loading-indicator>
              </a>
            </div>
          )}

          <PropertyAlertContactSettings form={form} />

          <div css={styles.formError}>{isPresent(formError) && formError}</div>
          <SubmitButton>{submitButtonText}</SubmitButton>
        </FormContainer>
      </form>
    </SettingsPane>
  );
};

export default compose(
  withModal,
  connect((state) => ({
    user: selectors.user.getUserDetails(state),
  })),
  Form.withFormStateInitialized("formState", initializer)
)(Profile);
