import { parse } from "query-string";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { getNewSearchURL, makeAllPartySearchValue } from "../../../utils";

export const PartyLink = ({ term, location, partyFields, department }) => {
  const { search } = location;

  const query = {
    ...parse(search),
    department,
  };

  const searchLink = getNewSearchURL({
    query,
    value: makeAllPartySearchValue({ term, partyFields }),
    key: "parties",
  });

  return (
    <Link to={searchLink} className="doc-preview-group__summary-group-text">
      {term}
    </Link>
  );
};

export default withRouter(PartyLink);
