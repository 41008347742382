import { css } from "@emotion/react";

const style = {
  bg: (hasFocus) => css`
    background: #193768;
    border-bottom: ${hasFocus && "1px solid rgba(255, 255, 255, 0.14)"};
    position: relative;
    width: 100%;
    list-style: none;
    display: flex;
    flex-wrap: no-wrap;
    overflow: hidden;
    grid-area: skip-links;
    max-height: ${hasFocus ? "unset" : 0};
  `,
  link: css`
    display: inline-block;
    background: none;
    color: #ffffff;
    font-size: 12px;
    font-weight: 600;
    padding: 12px 15px;

    &:focus {
      background: rgba(255, 255, 255, 0.94);
      color: #007bd6;
    }
  `,
};

export default style;
