import { css } from "@emotion/react";
import PropTypes from "prop-types";
import compose from "ramda/src/compose";
import isEmpty from "ramda/src/isEmpty";
import { connect } from "react-redux";
import { ErrorBar, Form } from "../../../../../components";
import { actions, selectors } from "../../../../../store";
import formConfig from "./formConfig";

const enhance = Form.withFormState(formConfig, "state");

const styles = {
  forgotpassword: css`
    background-color: #ffffff;
    border: 1px solid rgba(4, 29, 68, 0.15);
    border-radius: 15px;
    margin: 100px auto auto;
    width: 400px;
  `,

  header: css`
    padding: 30px 30px 0;
  `,

  headertext: css`
    color: #041d44;
    font-size: 26px;
    text-align: center;
  `,

  bodywrap: css`
    padding: 0 30px 30px;
  `,

  text: css`
    color: #041d44;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
    text-align: center;
  `,

  inlinewrapper: css`
    display: flex;
    align-items: center;
    position: relative;
  `,

  inlineinput: css`
    width: 70%;
  `,

  submitbutton: css`
    background-color: #006dd5;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    position: absolute;
    right: 0;
    color: #ffffff;
    font-size: 15px;
    font-weight: 400;
    height: 32px;
    padding: 0 0.5rem;
    width: 31%;
  `,
};

const SendPassword = (props) => {
  const {
    updateFormValue,
    updateFormError,
    state,
    resetPassword,
    isLoading,
    resetError,
  } = props;

  const formActions = {
    onChange: updateFormValue,
  };

  const form = Form.buildForm(formConfig, state, formActions);

  const email = state.email.value;

  const handleSubmit = (event) => {
    const isValidForm = Form.checkIfFormValid(
      formConfig,
      state,
      updateFormError
    );

    if (isValidForm) {
      resetPassword({ email });
    }

    event.preventDefault();
  };

  const errorMessage = isEmpty(resetError)
    ? ""
    : "No account with the provided email was found.";

  return (
    <form css={styles.forgotpassword} onSubmit={handleSubmit}>
      <div css={styles.header}>
        <h1 css={styles.headertext}>Forgot Password</h1>
      </div>
      <ErrorBar error={errorMessage} />
      <div css={styles.bodywrap}>
        <div css={styles.text}>
          Enter your email address below. You will receive an email with a link
          to create a new password.
        </div>
        <div>
          <div css={styles.inlinewrapper}>
            <div css={styles.inlineinput}>{form.email}</div>
            <button css={styles.submitbutton} type="submit">
              {isLoading ? "Loading..." : "Submit"}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

SendPassword.displayName = "ForgotPassword";
SendPassword.propTypes = {
  updateFormValue: PropTypes.func.isRequired,
  updateFormError: PropTypes.func.isRequired,
  state: PropTypes.shape({
    email: PropTypes.shape({
      value: PropTypes.string,
      error: PropTypes.string,
    }),
  }),
  resetPassword: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export const mapStateToProps = (state) => ({
  isLoading: selectors.password.getLoading(state),
  resetError: selectors.password.getResetError(state),
});

export const mapDispatchToProps = (dispatch) => ({
  resetPassword: ({ email }) =>
    dispatch(actions.password.resetPassword({ email })),
});

export const EnhancedSendPassword = compose(
  enhance,
  connect(mapStateToProps, mapDispatchToProps)
)(SendPassword);

export default EnhancedSendPassword;
