import PropTypes from "prop-types";
import compose from "ramda/src/compose";
import propEq from "ramda/src/propEq";
import { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Redirect } from "react-router-dom";
import { branch, renderComponent } from "recompose";
import { CheckEmail } from "../../../components";
import { actions, selectors } from "../../../store";
import { parseUrl } from "../../../utils";
import { SendPassword } from "./components";

export class ForgotPassword extends PureComponent {
  componentWillUnmount() {
    this.props.clearState();
  }

  render() {
    const { email, resetIsSent } = this.props;
    const checkEmailView = <CheckEmail type="password" email={email} />;
    const sendPasswordView = <SendPassword />;

    return resetIsSent ? checkEmailView : sendPasswordView;
  }
}

ForgotPassword.displayName = "ForgotPassword";
ForgotPassword.propTypes = {
  resetIsSent: PropTypes.bool.isRequired,
};

export const mapStateToProps = (state) => ({
  email: selectors.password.getEmailToReset(state),
  resetIsSent: selectors.password.getResetIsSent(state),
  loggedIn: selectors.user.getLoggedIn(state),
  firstWorkspace: selectors.workspaces.getFirstWorkspace(state),
});

export const mapDispatchToProps = (dispatch) => ({
  clearState: () => dispatch(actions.password.clearState()),
});

const isLoggedIn = propEq("loggedIn", true);

const RedirectToWorkspace = (props) => {
  const { id, path } = props.firstWorkspace;
  const { pathname, search } = parseUrl(path);

  return (
    <Redirect
      to={{
        pathname,
        search,
        state: {
          workspaceId: id,
        },
      }}
    />
  );
};

const withRouteProtection = branch(
  isLoggedIn,
  renderComponent(RedirectToWorkspace)
);

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withRouteProtection
)(ForgotPassword);
