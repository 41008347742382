import { css } from "@emotion/react";
import { FilterMenu } from "@kofile/icons";
import always from "ramda/src/always";
import and from "ramda/src/and";
import compose from "ramda/src/compose";
import concat from "ramda/src/concat";
import cond from "ramda/src/cond";
import equals from "ramda/src/equals";
import filter from "ramda/src/filter";
import flip from "ramda/src/flip";
import has from "ramda/src/has";
import head from "ramda/src/head";
import identity from "ramda/src/identity";
import ifElse from "ramda/src/ifElse";
import includes from "ramda/src/includes";
import indexBy from "ramda/src/indexBy";
import isEmpty from "ramda/src/isEmpty";
import last from "ramda/src/last";
import length from "ramda/src/length";
import map from "ramda/src/map";
import not from "ramda/src/not";
import partial from "ramda/src/partial";
import pick from "ramda/src/pick";
import pipe from "ramda/src/pipe";
import prop from "ramda/src/prop";
import T from "ramda/src/T";
import values from "ramda/src/values";
import without from "ramda/src/without";
import { PureComponent } from "react";
import { connect } from "react-redux";
import { withProps } from "recompose";
import modalChildSignIn from "../../../../../App/screens/SignIn/modal";
import { SelectAction } from "../../../../../components";
import { withModal } from "../../../../../hoc";
import { actions, selectors } from "../../../../../store";
import exportResultsModal from "../../../../../units/checkout.modal/export-results";
import modalChild from "../../../../../units/checkout.modal/modal";
import { getFromObj, notEmpty, randomString } from "../../../../../utils";
import { ActionButton } from "./_results.header.action-button";
import { SectionWrap } from "./_section-wrap";
import { filterActions } from "./actions-filter";
import {
  SortSelector,
  ListTypes,
  EditSearch,
  ResultCountSelector,
  CertificationStatus,
} from "./components";
import { SearchText } from "./search-text";

const style = {
  headerRight: css`
    flex: 1;
    display: grid;
    padding: 8px 0;
    gap: 8px;
    place-items: center;
    grid-template-columns: repeat(3, min-content);
  `,
  dateLabelMeta: css`
    white-space: nowrap;
    font-size: 12px;
    color: rgba(4, 29, 68, 1);
    font-weight: 600;
  `,
};

export class Header extends PureComponent {
  componentWillReceiveProps(nextProps) {
    const nextExportResults = nextProps.exportResultsState.data;
    const curExportResults = this.props.exportResultsState.data;
    const checkoutResults = and(
      notEmpty(nextExportResults),
      not(equals(curExportResults, nextExportResults))
    );

    if (checkoutResults) {
      nextProps.openExportResultsModal();
    }
  }

  componentWillUnmount() {
    this.props.clearExportResults();
  }

  render() {
    const {
      availablePageActions,
      countText,
      departmentName,
      listType,
      mappedActions = [],
      updateFilterMenuOpen,
      updateViewType,
      viewModes,
    } = this.props;

    const openFilterMenu = () => updateFilterMenuOpen(true);

    const searchActions = () => {
      if (isEmpty(mappedActions)) return null;

      const canAddToCart =
        includes("addToCart", availablePageActions) &&
        includes("addToCart", mappedActions);

      const directAction = mappedActions[0].action;

      const dropdownLabel = canAddToCart
        ? `Add to Cart${countText}`
        : mappedActions[0].label;

      if (length(mappedActions) === 1) {
        return (
          <ActionButton onClick={directAction}>{dropdownLabel}</ActionButton>
        );
      }

      return (
        <SelectAction
          label={dropdownLabel}
          options={mappedActions}
          directAction={directAction}
          dataTestId="resultsSelectionDropdown"
        />
      );
    };

    return (
      <header className="search-results-header">
        <section className="search-results-header__left-wrap">
          <button
            className="search-results-header__filter-menu-button"
            onClick={openFilterMenu}
          >
            <FilterMenu
              id="filter-menu-icon"
              className="search-results-header__filter-menu-icon"
            />
          </button>
          <SectionWrap>
            <SearchText {...this.props} />
            <div className="search-results-header__row">
              <div className="search-results-header__inline-flex">
                <div className="search-results-header__department-text">
                  <span className="search-results-header__bold-text">
                    Department:
                  </span>
                  &nbsp;
                  {departmentName}
                </div>
                <SortSelector {...this.props} />
                <ResultCountSelector {...this.props} />
                <EditSearch {...this.props} />
              </div>
            </div>
          </SectionWrap>
        </section>
        <section>
          <div css={style.headerRight}>
            <CertificationStatus css={style.dateLabelMeta} />
            <ListTypes
              onChange={updateViewType}
              currentView={listType}
              viewModes={viewModes}
            />
            <div css={{ whiteSpace: "nowrap" }}>{searchActions()}</div>
          </div>
        </section>
      </header>
    );
  }
}

const bulkActionLabels = {
  addToCart: ["Add all to Cart", "Add to Cart"],
  addToNotepad: ["Add all to Notepad", "Add to Notepad"],
  exportResults: ["Export all Results", "Export all Results"],
  expressCheckout: ["Express Checkout", "Express Checkout"],
};

const appendRight = flip(concat);

const applyCount = ifElse(isEmpty, always(head), (count) =>
  pipe(last, appendRight(count))
);

const getLabelsMap = getFromObj(bulkActionLabels);

export const mapStateToProps = (state, ownProps) => {
  const selectedDocuments = selectors.workspaces.getSelectedDocumentIDs(state);
  const resultsToExport = selectors.documents.getDocumentsForExport(state);
  const departmentConfig = ownProps.department;

  const selectedDocumentsCount =
    selectors.workspaces.getSelectedDocumentsCount(state);

  const { availablePageActions } = departmentConfig.searchResult;
  const departmentCode = departmentConfig.code;
  const workspaceID = selectors.workspaces.getActiveWorkspaceID(state);
  const isLoggedIn = selectors.user.getLoggedIn(state);
  const exportResultsState = selectors.exportResults.getExportResults(state);
  const shoppingCartId = selectors.cart.getCartId(state);
  const documentsInCart = selectors.cart.getRegularCartDocs(state);
  const documentsHash = selectors.documents.getActiveWorkspaceDataHash(state);
  const documentsByOrder =
    selectors.documents.getActiveWorkspaceDataOrder(state);

  const documentsWithoutImage =
    selectors.documents.getActiveWorkspaceDocsWithoutImage(state);

  return {
    selectedDocuments,
    selectedDocumentsCount,
    selectedDocumentsOrdered:
      selectors.workspaces.getSelectedDocumentsOrdered(state),
    availablePageActions,
    isDepartmentCertified: departmentConfig.isCertified,
    departmentName: departmentConfig.name,
    workspaceID,
    isLoggedIn,
    exportResultsState,
    resultsToExport,
    shoppingCartId,
    departmentCode,
    documentsInCart,
    documentsWithoutImage,
    documentsHash,
    documentsByOrder,
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => ({
  addToCart: (cartData) => () => {
    const { modal } = ownProps;

    dispatch(
      actions.user.addDocumentsToCart({
        cartID: cartData.id,
        itemIDs: cartData.items,
        department: cartData.department,
      })
    );

    const onSubmit = () => dispatch(actions.workspaces.removeAllDocuments());

    modal.open(modalChild({ onSubmit, close: modal.close }));
  },
  expressCheckout: (payload) => dispatch(actions.user.expressCheckout(payload)),
  openSignIn: (actionOnSignIn) => {
    const { modal } = ownProps;

    modal.open(modalChildSignIn({ actionOnSignIn, close: modal.close }));
  },
  requestExportResults: (results) =>
    dispatch(actions.exportResults.exportResults(results)),
  openExportResultsModal: () => {
    ownProps.modal.open(exportResultsModal(ownProps.modal.close));
  },
  openCheckoutResults: (checkoutProps) => {
    const { modal } = ownProps;

    modal.open(
      modalChild({
        type: "CheckoutResults",
        docs: [],
        ...checkoutProps,
        close: modal.close,
      })
    );
  },
  clearExportResults: () =>
    dispatch(actions.exportResults.clearExportResults()),
});

const withActions = withProps((props) => {
  const {
    countText,
    departmentCode,
    addToCart,
    shoppingCartId,
    selectedDocuments = [],
    isLoggedIn,
    modal,
    documentsHash,
    documentsToReject,
  } = props;

  const getLabelForAction = pipe(getLabelsMap, applyCount(countText));

  const expressCheckout = (docs) => () => {
    const checkoutProps = {
      department: departmentCode,
      itemIDs: docs,
      cartID: shoppingCartId,
    };

    props.expressCheckout(checkoutProps);

    const openExpressCheckout = () =>
      modal.open(modalChild({ close: modal.close }));

    return isLoggedIn
      ? openExpressCheckout()
      : modal.open(
          modalChildSignIn({
            actionOnSignIn: openExpressCheckout,
            close: modal.close,
          })
        );
  };

  const mapActions = map(
    cond([
      [
        equals("addToCart"),
        (val) => {
          const items = getDocumentIDsToCheckout({
            selectedDocuments,
            documentsToReject,
            documentsByHash: documentsHash,
          });

          return {
            label: getLabelForAction(val),
            action: addToCart({
              items,
              id: shoppingCartId,
              department: departmentCode,
            }),
            value: val,
            disabled: items.length === 0,
          };
        },
      ],
      [
        equals("expressCheckout"),
        (val) => {
          const items = getDocumentIDsToCheckout({
            selectedDocuments,
            documentsToReject,
            documentsByHash: documentsHash,
          });

          return {
            label: getLabelForAction(val),
            action: expressCheckout(items),
            value: val,
            disabled: items.length === 0,
          };
        },
      ],
      [
        equals("exportResults"),
        (val) => {
          const {
            columns,
            resultsToExport = {},
            department,
            isLoggedIn,
            openSignIn,
            selectedDocumentsOrdered,
          } = props;

          const mappedColumns = columns.map(({ name, key }) => ({
            text: name,
            propertyName: key,
          }));

          const userHasSelectedDocuments = !isEmpty(selectedDocumentsOrdered);

          const documentForSelection = userHasSelectedDocuments
            ? selectedDocumentsOrdered
            : Object.values(resultsToExport);

          const propertyNames = map(prop("propertyName"), mappedColumns);

          const rows = map(pick(propertyNames), documentForSelection);

          const exportPayload = {
            rows,
            department: department.code,
            columns: mappedColumns,
            requestId: randomString(),
          };

          const exportRequestAction = partial(props.requestExportResults, [
            exportPayload,
          ]);
          const openSignInModal = partial(openSignIn, [exportRequestAction]);

          return {
            label: getLabelForAction(val),
            action: isLoggedIn ? exportRequestAction : openSignInModal,
            value: val,
          };
        },
      ],
      [
        T,
        (val) => ({
          label: getLabelForAction(val),
          action: () => {},
          value: val,
        }),
      ],
    ])
  );

  return { mappedActions: mapActions(filterActions(props)) };
});

const withCountText = withProps((props) => {
  const {
    selectedDocuments,
    documentsInCart,
    documentsWithoutImage,
    selectedDocumentsCount,
  } = props;

  const selectedDocumentsMap = indexBy(identity, selectedDocuments);
  const documentsToReject = concat(documentsInCart, documentsWithoutImage);
  const rejectedCount = compose(
    length,
    filter((docId) => has(docId, selectedDocumentsMap))
  )(documentsToReject);

  const effectiveDocumentsCount = selectedDocumentsCount - rejectedCount;

  const countText =
    effectiveDocumentsCount > 0 ? ` (${effectiveDocumentsCount})` : "";

  return {
    countText,
    documentsToReject,
  };
});

const enhance = compose(
  withModal,
  connect(mapStateToProps, mapDispatchToProps),
  withCountText,
  withActions
);

export default enhance(Header);

// --------------------------------------------------------------------- private

function getDocumentIDsToCheckout({
  selectedDocuments,
  documentsToReject,
  documentsByHash,
}) {
  const docIDsToUse = isEmpty(selectedDocuments)
    ? compose(map(prop("id")), values)(documentsByHash)
    : selectedDocuments;

  return without(documentsToReject, docIDsToUse);
}
