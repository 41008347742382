import { css } from "@emotion/react";

export const Button = ({ onClick, disabled = false, text }) => (
  <button
    css={css`
      background: #006dd5;
      border: 1px solid rgba(4, 29, 68, 0.1);
      border-radius: 4px;
      color: #ffffff;
      padding: 6px 12px;
    `}
    onClick={onClick}
    disabled={disabled}
  >
    {text}
  </button>
);
