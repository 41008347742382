import styled from "@emotion/styled";

export const Container = styled.header`
  background: linear-gradient(180deg, #193768 0%, #243f8a 100%);
  color: #fff;
  grid-area: header;
  display: grid;
  grid-template-areas:
    "logo county-name-prefix divider clerk-pic officials-list nav"
    "logo county-name        divider clerk-pic officials-list nav";
  grid-template-columns: repeat(4, min-content) auto min-content;
  gap: 3px 20px;

  @media print {
    display: none;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    grid-template-areas:
      "logo           county-name-prefix nav"
      "logo           county-name        nav"
      "officials-list officials-list     officials-list";
    grid-template-columns: repeat(2, min-content) auto;
    gap: 3px 10px;
  }
`;
