import { css } from "@emotion/react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectors } from "../../../store";
import { ClerkPicture } from "./_clerk-picture";
import { Container } from "./_container";
import Nav from "./components";

const style = {
  seal: (theme) => css`
    grid-area: logo;

    img {
      height: 80px;
      width: 80px;
      background: #fff;
      border-bottom-left-radius: 50%;
      border-bottom-right-radius: 50%;
      box-shadow: 0 2px 6px 0 rgba(4, 29, 68, 0.4);
      padding: 3px 6px 6px;
      margin-left: 15px;
      margin-bottom: 18px;

      @media screen and (max-width: ${theme.breakpoints.sm}px) {
        border-radius: 50%;
        height: 44px;
        width: 44px;
        padding: 3px;
        margin: 5px 0 5px 10px;
      }
    }
  `,
  countyNamePrefix: (theme) => css`
    color: rgba(255, 255, 255, 0.6);
    display: block;
    font-size: 15px;
    font-weight: 200;
    grid-area: county-name-prefix;
    white-space: nowrap;
    align-self: end;
    text-transform: uppercase;

    @media screen and (max-width: ${theme.breakpoints.sm}px) {
      font-size: 10px;
    }
  `,
  countyName: (theme) => css`
    color: #fff;
    display: block;
    font-size: 22px;
    grid-area: county-name;

    @media screen and (max-width: ${theme.breakpoints.sm}px) {
      font-size: 16px;
    }
  `,
  officialContainer: (theme) => css`
    grid-area: officials-list;
    align-self: center;
    display: flex;

    @media screen and (max-width: ${theme.breakpoints.sm}px) {
      background-color: #f5f6f7;
      color: black;
    }
  `,
  official: (theme) =>
    css`
      padding-right: 15px;
      white-space: nowrap;
      display: grid;
      grid-template-areas:
        "title divider"
        "name  divider";

      .name {
        color: #fff;
        display: block;
        font-weight: 400;
        opacity: 0.75;
        grid-area: name;
      }

      .title {
        color: rgba(255, 255, 255, 0.6);
        display: block;
        font-size: 15px;
        font-weight: 200;
        text-transform: uppercase;
        padding-bottom: 6px;
        grid-area: title;
      }

      .divider {
        grid-area: divider;
        height: 100%;
        place-self: start;
        margin-left: 20px;
      }

      @media screen and (max-width: ${theme.breakpoints.sm}px) {
        padding: 10px;

        .title {
          font-size: 12px;
        }

        .name,
        .title {
          color: #041d44;
        }

        .divider {
          display: block;
          background-color: rgba(0, 0, 0, 0.1);
        }
      }
    `,
  divider: (theme) => css`
    grid-area: divider;
    width: 1px;
    background-color: rgba(255, 255, 255, 0.5);
    height: 35%;
    place-self: center;

    @media screen and (max-width: ${theme.breakpoints.sm}px) {
      display: none;
    }
  `,
};

export const Header = () => {
  const { configuration, workspaces } = selectors;

  const tenantUrl = useSelector(configuration.getTenantUrl);
  const tenantClerk = useSelector(configuration.getCountyClerk);
  const clerkTitle = useSelector(configuration.getClerkTitle);
  const clerkPictureUrl = useSelector(configuration.getClerkPictureUrl);
  const officialsList = useSelector(configuration.getOfficials);
  const countyName = useSelector(configuration.getCountyName);
  const sealUrl = useSelector(configuration.getSealUrl);
  const tenantName = useSelector(configuration.getTenantName);
  const firstWorkspace = useSelector(workspaces.getFirstWorkspace);
  const administrativeDivision = useSelector(
    configuration.getAdministrativeDivision
  );
  const [path, search = ""] = firstWorkspace.path.split("?");
  const pathname = tenantUrl ?? path;

  const linkProps = {
    pathname,
    search: tenantUrl ? "" : search,
    state: {
      workspaceID: firstWorkspace.id,
    },
  };

  return (
    <Container>
      <Link className="seal" css={style.seal} to={linkProps} aria-label="Home">
        <img alt={`seal for ${countyName}`} src={sealUrl} />
      </Link>

      <span css={style.countyNamePrefix}>{administrativeDivision}</span>

      <span css={style.countyName}>{tenantName}</span>

      <div css={style.divider} />

      {clerkPictureUrl && (
        <ClerkPicture>
          <img
            alt={`${clerkTitle} ${tenantClerk} for ${countyName}`}
            src={clerkPictureUrl}
          />
        </ClerkPicture>
      )}

      <ul css={style.officialContainer}>
        {officialsList.map((official, index) => (
          <li css={style.official} key={index}>
            <span className="title" data-testid="clerkTitle">
              {official.title}
            </span>
            <span className="name">{official.name}</span>
            <div className="divider" css={style.divider} />
          </li>
        ))}
      </ul>

      <Nav />
    </Container>
  );
};

export default Header;
