import { css } from "@emotion/react";

export const styles = {
  container: css`
    background-color: #ffffff;
    box-sizing: border-box;
    display: grid;
    grid-template:
      "title title  title" 100px
      ".     body   .    " auto
      ".     footer .    " min-content / 35px auto 35px;
    height: 500px;
    max-height: 1150px;
    min-width: 700px;
    width: 100%;
  `,
  button: css`
    border-radius: 4px;
    font-family: "Source Sans Pro";
    font-size: 15px;
    letter-spacing: 0.23px;
    line-height: 15px;
    margin: 0 5px;
    padding: 10px 15px;
  `,
  select: css`
    align-self: flex-end;

    .react-downshift-select__item {
      white-space: nowrap;
    }

    .react-downshift-select__items {
      width: auto;
    }

    .select__control-wrap {
      color: #006dd5;
    }

    .select__indicator {
      margin-left: 10px;
    }
  `,

  title: css`
    align-self: end;
    border-bottom: 2px solid #d8dde6;
    display: flex;
    grid-area: title;
    justify-content: space-between;
    margin-bottom: 15px;
    padding: 0 28px 23px;

    h1 {
      color: #041d44;
      font-family: "Source Sans Pro";
      font-size: 26px;
      letter-spacing: 0.4px;
      line-height: 33px;
    }
  `,
  body: css`
    color: #041d4499;
    font-family: "Source Sans Pro";
    font-size: 14px;
    grid-area: body;
    line-height: 17px;
    margin-bottom: 20px;
    max-height: 100%;
    overflow: auto;

    p {
      margin-bottom: 14px;
    }
  `,
};
