import lensPath from "ramda/src/lensPath";
import view from "ramda/src/view";
import { CHECKOUT_TYPES, ENTITY_TYPES } from "../../constants";

const headerTextMap = {
  [CHECKOUT_TYPES.NORMAL]: {
    [ENTITY_TYPES.DOCUMENT]: "Add to Cart",
    [ENTITY_TYPES.ATTACHMENT]: "Add Attachment to Cart",
  },
  [CHECKOUT_TYPES.EXPRESS]: {
    [ENTITY_TYPES.DOCUMENT]: "Express Checkout",
    [ENTITY_TYPES.ATTACHMENT]: "Express Checkout",
    [ENTITY_TYPES.EXPORT]: "Export Results",
  },
};

const Header = ({ checkoutType, entityType, entityCount }) => {
  const text = view(lensPath([checkoutType, entityType]), headerTextMap);
  const countText = entityCount ? ` (${entityCount})` : "";

  return (
    <div className="checkout__title-wrap">
      <div className="checkout__title-text">
        {text}
        {countText}
      </div>
    </div>
  );
};

export default Header;
