import { css } from "@emotion/react";
import take from "ramda/src/take";
import takeLast from "ramda/src/takeLast";
import Tooltip from "rc-tooltip";
import { Fragment } from "react";
import { toArray } from "../../../../../../../utils";

export const CHILD_LIMIT = 2;

const styles = {
  wrapper: css`
    padding: 10px;
    max-width: 150px;
    max-height: 400px;
    overflow: auto;
    background: #ffffff;
  `,
  moreText: css`
    color: #041d44;
    font-size: 13px;
  `,
};

export const TruncatedList = (props) => {
  const { children, id } = props;
  const childrenArray = toArray(children);
  const shouldTruncate = childrenArray.length > CHILD_LIMIT;
  const truncatedChildren = take(CHILD_LIMIT, childrenArray);
  const numExcessChildren = childrenArray.length - truncatedChildren.length;
  const excessChildren = takeLast(numExcessChildren, childrenArray);

  if (shouldTruncate) {
    const tooltip = <div css={styles.wrapper}>{excessChildren}</div>;

    return (
      <Fragment>
        {truncatedChildren}
        <Tooltip
          trigger="hover"
          placement="bottomLeft"
          overlay={tooltip}
          overlayClassName="tooltip-overlay"
          distance={0}
          id={id}
        >
          <p aria-describedby={id} css={styles.moreText}>
            And {numExcessChildren} more...
          </p>
        </Tooltip>
      </Fragment>
    );
  }

  return childrenArray;
};

TruncatedList.displayName = "TruncatedList";
