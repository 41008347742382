import styled from "@emotion/styled";

const LoginRegisterFooter = styled.p`
  color: rgba(4, 29, 68, 0.8);
  text-align: center;
  font-size: 13px;
  padding: 24px;

  & > a {
    color: #006dd5;
    text-decoration: underline;
  }
`;

export default LoginRegisterFooter;
